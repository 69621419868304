import React from "react";
import { ChargeWrapper } from "./ChargePromo.style";
import Character from "Assets/Charge/character.png";
import HeaderIcon from "Assets/Icons/HeaderIcon";
import { Typography } from "@material-ui/core";
import FirstHeader from "Assets/Charge/ONE.png";
import SECONDHeader from "Assets/Charge/TWO.png";
import ThirdHeader from "Assets/Charge/THREE.png";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Timer from "react-compound-timer";
import CardFramImg from 'Assets/Charge/card-frame.png'

function Reward(props) {
    const { claimTimer, expire_promo } = props;
    const history = useHistory();
    const language = useSelector(state => state.Auth.lang);
    const { t } = useTranslation();
    const { REACT_APP_DOWNLOAD_URL } = process.env;

    //const [expire_promo, setExpire_promo] = useState(false)
    const redirectHandler = (item) => {
        if (item.id === 1) {
            history.push(`/${language}/shop`);
        } else if (item.id === 2) {
            window.open(`${REACT_APP_DOWNLOAD_URL}/${language}/games/pwi/rewards`)
        }
    }
    return (
        <ChargeWrapper>
            <div className="header">
                <div className="dummy-line" />
                <div className="title">
                    <HeaderIcon />
                    <Typography variant="h6" >
                        {t("Charge.CHARGEPROMO")}
                    </Typography>
                </div>
                <div className="dummy-line reverse" />
            </div>

            <div className="card-section">
                <div className={`top-section ${expire_promo && "min-height"}`}>
                    <div className={`left-section ${expire_promo && "align-top"}`}>
                        {
                            !expire_promo ? (<>
                                {claimTimer > 0 && (<Timer
                                    initialTime={claimTimer}
                                    direction="backward"


                                >
                                    <div className="title">{t("Charge.RemainingTime")}</div>

                                    <div className="time-section">
                                        <div className="division">
                                            <div className="time"><strong><Timer.Days /></strong></div>
                                            <div className="representation">{t("Charge.day")}</div>
                                        </div>
                                        <div className="division">
                                            <div className="time"><strong><Timer.Hours /></strong></div>
                                            <div className="representation">{t("Charge.HOURS")}</div>
                                        </div>
                                        <div className="division">
                                            <div className="time"><strong><Timer.Minutes /></strong></div>
                                            <div className="representation">{t("Charge.MINS")}</div>
                                        </div>
                                        <div className="division">
                                            <div className="time"><strong><Timer.Seconds /></strong></div>
                                            <div className="representation">{t("Charge.SECS")}</div>
                                        </div>
                                    </div>
                                </Timer>)}
                            </>)
                                : (
                                    <div className="expired-details-container flex">
                                        <Typography variant="h4" style={{ textDecoration: "italic" }}>
                                            <i>{t("Charge.UnfortunateMessage")}</i>
                                        </Typography>
                                        <Typography variant="h6">
                                            {t("Charge.VisitNewsForum")}
                                        </Typography>
                                    </div>)

                        }
                    </div>
                    {!expire_promo ? (<div className="right-section">
                        <img className="character-image" src={Character} alt="character" />
                        {/* <div className="image" /> */}
                    </div>) : (
                        <div className="right-section-expired">
                            <img className="character-image" src={Character} alt="character" />
                        </div>
                    )

                    }
                </div>


                {!expire_promo ? (<div className="bottom-section">
                    <div className="cards">
                        {CARDS.map((item, index) =>
                            <div className="reward-card">
                                <img src={CardFramImg} className="right-frame" alt="right-frame" />
                                <img src={CardFramImg} className="left-frame" alt="left-frame" />
                                <div className="id"><img src={item.header} alt="card-header" /></div>

                                <div className="reward-title">{t(item.title)}</div>
                                <div className="reward-description">{t(item.description)}</div>
                                {item.link && <div onClick={() => redirectHandler(item)} className="reward-visit">{t(item.link)}</div>}
                            </div>
                        )}

                    </div>

                    <div className="conditional-text">
                        <div className="description-container">
                            <Typography component="strong">
                                {t("Charge.HowItWorks")}
                            </Typography>
                            <Typography variant="subtitle1" >
                                {t("Charge.GoldMessage")}
                            </Typography>
                        </div>
                        <div className="description-container">
                            <Typography component="strong">
                                {t("Charge.BONUS")}
                            </Typography>
                            <Typography variant="subtitle1" >
                                {t("Charge.BonusMessage")}
                            </Typography>
                        </div>
                        <div className="description-container">
                            <Typography component="strong">
                                {t("Charge.REWARDDISTRIBUTION")}
                            </Typography>
                            <Typography variant="subtitle1" >
                                {t("Charge.DistributionMessage")}
                            </Typography>
                        </div>
                    </div>

                </div>) : null}
            </div>
        </ChargeWrapper>
    )

}

export default Reward;

const CARDS = [
    {
        id: 1,
        title: "Charge.EARNPOINTS",
        description: "Charge.PurchaseMessage",
        link: "Charge.VisitShop",
        header: FirstHeader
    },
    {
        id: 2,
        title: "Charge.CHOOSEREWARDS",
        description: "Charge.SpendRawardMessage",
        // link: "Charge.VisitReward",
        link: false, 
        header: SECONDHeader
    },
    {
        id: 3,
        title: "Charge.PLAYGAME",
        description: "Charge.FollowInstruction",
        link: "",
        header: ThirdHeader
    }
]
