import { Dialog } from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import ArrowIcon from "Assets/Charge/arrow-icon.png";
import CrossIcon from "Assets/Charge/cross.svg";
import CrossHoverIcon from "Assets/Charge/cross-hover.svg";
import ButtonIconDefault from 'Assets/Component 28 – 1 default.svg';
import ButtonIcon from 'Assets/Component 28 – 1.svg';
import CancelBtnDefault from "Assets/cancel_btn_default.svg";
import CancelBtn from "Assets/cancel_btn.svg"

const ellipsisAnimation = keyframes`
    25% {
        content: "";
    }
    50% {
        content: ".";
    }
    75% {
        content: "..";
    }
    100% {
        content: "...";
    }
`

export const RedeemDialog = styled(Dialog).attrs({
    className: 'RedeemDialog',
  })`
    .paper {
        max-width: 600px;
        width: 600px;
        height: 500px;
        border: 2px solid #503B2F;
        background-color: #FFF2E6;
        overflow: visible;

        @media only screen and (max-width: 480px) {
            height: auto;
        }
    }

    .container {
        height: 100%;
        padding: 40px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width: 480px) {
            padding: 32px;
        }

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            background-image: url(${CrossIcon});
            height: 27px;
            width: 27px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            &:hover {
                background-image: url(${CrossHoverIcon});
            }

            // Preload images and prevent the 'flash' on first hover
            &:after {
                position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
                content: url("${CrossHoverIcon}"); // load images
            }
        }

        .content {
            height: 100%;

            .success {
                font-size: 16px;
                margin : 40px 0 20px;
                color: #503B2F;
                ${responsive.PHABLET`
                    font-size : 14px;
                `}
                ${responsive.MOBILE`
                    font-size : 12px;
                `}
            }
            .desc {
                font-size: 16px;
                color: #503B2F; 

                ${responsive.PHABLET`
                    font-size : 14px;
                `}
                ${responsive.MOBILE`
                    font-size : 12px;
                `}
            }
            .history {
                font-size: 16px;
                font-family: ${FONTS.PRIMARY_BOLD};
                text-decoration : underline;
                margin : 40px 0 0;
                cursor: pointer;
                font-weight: 600;
                color: black;
                ${responsive.PHABLET`
                    font-size : 14px;
                `}
                ${responsive.MOBILE`
                    font-size : 12px;
                `}
            }
            .title {
                font-size: 20px;
                font-family: ${FONTS.PRIMARY_BOLD};
                font-weight: 600;
                text-transform: uppercase;
                color: black;
                gap: 1rem;
                align-items: center;
                h6 {
                    font-weight: 700;
                    margin-right: 20px;
                }
                ${responsive.PHABLET`
                    font-size : 16px;
                `}
                ${responsive.MOBILE`
                    font-size : 14px;
                `}
                & svg {
                    min-width: 30px;
                }
            }
    
            .chosen {
                font-size: 16px;
                margin : 30px 0;
                
                ${responsive.TABLET`
                    margin : 20px 0;
                `}
                ${responsive.PHABLET`
                    font-size : 14px;
                `}
                ${responsive.MOBILE`
                    font-size : 12px;
                `}
                .item {
                    font-family: ${FONTS.PRIMARY_BOLD};
                }
            }
    
            .spend-points {
                font-size: 16px;
                
                ${responsive.PHABLET`
                    font-size : 14px;
                `}
                ${responsive.MOBILE`
                    font-size : 12px;
                `}
                .highlight {
                    font-family: ${FONTS.PRIMARY_BOLD};
                    margin : 0 3px;
                }
            }
            .server,.character {
                width: 100%;
                margin : 20px 0;
                fieldset {
                    border-color: transparent;
                    border-radius: 4px;
                }
                .auto-root {
                    background-color: #ebdfd4;
                    border-radius: 2px;
                }
                .auto-input {
                    padding: 0;
                }
                .popupIndicator {
                    background-image: url(${ArrowIcon});
                    height: 15px;
                    width: 15px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;

                    svg {
                        display: none;
                    }
                }
                .MuiInputBase-input {
                    height: 1.4em;
                }
            }

            .loading-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 90%;

                .loading-text {
                    font-size: 22px;
                    margin: 1em;
                    &:after {
                        overflow: hidden;
                        display: inline-block;
                        vertical-align: bottom;
                        animation: ${ellipsisAnimation} 1s infinite .3s;
                        animation-fill-mode: forwards;
                        content: "...";
                        width: 1.25em;
                    }
                }
            }

            .error {
                color: #D82B2B;
                margin: 1em 0;
                a {
                    color: #321a19;
                }
                &.confirmed-error {
                    margin: 2em 0;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: 480px) {
                flex-direction: column;
            }
            .btn {  
                width: 167px;
                height: 41px;
                border: none;
                display: block;
                background-color: transparent;
                background-image: url("${ButtonIconDefault}");
                text-transform: uppercase;
                cursor: pointer;
                margin: 0 0 0 10px;
                @media only screen and (max-width: 480px) {
                    margin: 16px 0 0 0;
                }
                &:hover:enabled {
                    background-image: url("${ButtonIcon}");
                }
                &.primary {
                    color : #fff;
                }
                &.secondary {
                    color : black;
                    background-image: url("${CancelBtnDefault}");
                    &:hover {
                        background-image: url("${CancelBtn}");
                    }
                }
                // Preload images and prevent the 'flash' on first hover
                &:after {
                    position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
                    content: url("${ButtonIcon}") url("${CancelBtn}"); // load images
                }
            }
        }
    }
`