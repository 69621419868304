export const URL_USER       = "/user";
export const URL_LOGIN      = "/login";

export const URL_NEWS       = "/news";
export const URL_NEWS_DETAILS = "/news-details"

export const URL_SHOP = "/shop"

export const URL_CHARGE = "/charge";
export const URL_CHARGE_HISTORY = "/charge-history";

export const URL_SUCCESS = "/success"

export const PAGE_NOT_FOUND = "/page-not-found"

export const URL_HOME = "/:lang/";