import React from "react";
import { NewsDetailsBodyWrapper } from "./NewsDetails.style";
// import NewsDetailsImage from "Assets/images/News/NewsDetails.png";
import { Button, Typography, FormControl, Select, MenuItem } from "@material-ui/core";
import PWIButton from '../../../Commons/Button/Button';
// import TweeterLogo from "Assets/images/News/tweeter.png";
// import FacebookLogo from "Assets/images/News/facebook.png";
import Carousel from "react-material-ui-carousel";
import FallbackImage from "Assets/News/news-fallback.webp";
import RelatedNews from "../RelatedNews/RelatedNews";
import { COLORS } from "Styles/Constants";
import NextIcon from "Assets/left_default.png";
import NextHoverIcon from "Assets/left_hover.png"
import FooterDivider from "Assets/Footer_divider_news_details.png";
import { ReactComponent as FaceBookBlackIcon } from "Assets/Facebook_black.svg"
import { ReactComponent as TweeterIcon } from "Assets/Tweeter_black.svg"
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "Components/Website.style";
import HeaderIcon from 'Assets/Icons/HeaderIcon';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import Api from "Helpers/ApiHandler";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function NewsDetailsBody(props) {
    const { newsArticleData, getNewsArticleData } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [rightArrowHover, setRightArrowHover] = useState(false);
    const [leftArrowHover, setLeftArrowHover] = useState(false);
    const [relatedNews, setRelatedNews] = useState([]);
    const [relatedNewsIndex, setRelatedNewsIndex] = useState(0);
    const [mobileNewsShown, setMobileNewsShown] = useState(2);
    const [loading, setLoading] = useState(false);
    const language = useSelector(state => state.Auth.lang)
    const { REACT_APP_ACCOUNT_URL } = process.env;

    const validFilters = {
        "pwi-community": t("News.COMMUNITY"),
        "pwi-media": t("News.MEDIA"),
        "dev-blog": t("News.DEVBLOG"),
        "patch-notes": t("News.PATCHNOTES"),
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (newsArticleData?.tags) {
            getRelatedNews(newsArticleData?.tags);
        }
        // eslint-disable-next-line
    }, [newsArticleData, language]);

    const getRelatedNews = async (tags) => {
        const relatedTag = tags.find(tag => validFilters.hasOwnProperty(tag)) || "";
        setLoading(true);
        let response = await new Api().get("games/pwi/news?field[]=images.img_microsite_thumbnail", {
            params: {
                limit: 11,
                offset: 0,
                tag: relatedTag,
            },
            skipAuth: true,
        });
        const relatedNewsArray = response.news.filter((newsItem) => newsItem.id !== newsArticleData?.id);
        setRelatedNews(relatedNewsArray);
        setLoading(false);
    }

    const handleBreadcrumbs = (filter = null) => {
        history.push({
            pathname: `/${language}/news`,
            hash: filter ? `#${filter}` : null
        })
    }

    const rightArrowMouseInandler = () => {
        setRightArrowHover(true);
    }

    const rightArrowMouseOutHandler = () => {
        setRightArrowHover(false);
    }

    const leftArrowMouseInandler = () => {
        setLeftArrowHover(true);
    }

    const leftArrowMouseOutHandler = () => {
        setLeftArrowHover(false);
    }

    const getNext = () => {
        const lastIndex = relatedNews.length - 1;
        if (relatedNewsIndex + 2 > lastIndex) {
            setRelatedNewsIndex(0);
        } else {
            setRelatedNewsIndex(index => index + 2);
        }
    }

    const getPrev = () => {
        const lastIndex = relatedNews.length - 1;
        if (relatedNewsIndex - 2 < 0) {
            if (lastIndex % 2 === 0) {
                setRelatedNewsIndex(lastIndex);
            } else {
                setRelatedNewsIndex(lastIndex - 1);
            }
        } else {
            setRelatedNewsIndex(index => index - 2);
        }
    }

    const getHeaderFilter = () => {
        let innerArray = []
        if (newsArticleData?.tags?.length) {
            newsArticleData.tags.forEach(tagName => {
                if(Object.keys(validFilters).includes(tagName)) {
                    innerArray.push({
                        tagName: tagName,
                        caption: validFilters[tagName]
                    });
                }
            });
        }

        return innerArray
    }

    return (
        <NewsDetailsBodyWrapper>
            <div className="news-details-body-container flex f-v-center f-h-center full-width">
                <Container>
                    <div className="news-details-header-container flex f-v-center f-h-center ">
                        <div className="news-details-header-image flex f-v-center f-h-center">
                            <div style={{ backgroundImage: `url(${newsArticleData?.images?.img_microsite_background?.url ?? FallbackImage})` }} className="news-details-image" >
                                <div className='border-container'></div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container style={{ zIndex: 2 }}>
                    <div className="news-body-content">
                        {
                            windowDimensions.width < 820 ? (
                                <div className="form-control-container">
                                    <FormControl variant="outlined" fullWidth className={"body-sub-header-select"}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            onChange={(event) => handleBreadcrumbs(event.target.value)}
                                        >   
                                            <MenuItem selected value={"ALL"}>{t('News.ALL')}</MenuItem>
                                            {getHeaderFilter().map((filter) => {
                                                return <MenuItem value={filter.tagName} className="to-uppercase">{filter.caption}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            ) : (
                                <div className="body-sub-header-container flex">
                                    <div onClick={() => handleBreadcrumbs("")} className="body-sub-header">
                                        <span>{t('News.ALL')}</span>
                                    </div>
                                    {getHeaderFilter().map((filter) => {
                                        return <div onClick={() => handleBreadcrumbs(filter.tagName)} key={filter.tagName} className="body-sub-header">
                                            <span>{filter.caption}</span>
                                        </div>
                                    })}
                                </div>
                            )
                        }
                        <div className="news-body-title-container">
                            <div className="news-body-sub-title">
                                {newsArticleData.title} {" - "} {newsArticleData.summary}
                            </div>
                            <div className="news-body-by">
                                By <strong>{newsArticleData.author}</strong> {moment(newsArticleData.updated).format("MMMM DD, YYYY hh:mm A")}
                            </div>
                        </div>
                        <div className="news-body-text-container flex f-v-center f-h-center">
                            <div className="news-body">
                                <div dangerouslySetInnerHTML={{ __html: newsArticleData.content }} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="news-details-footer-container flex f-v-center f-h-center full-width">
                <Container>
                    <div className="news-details-footer-content flex f-v-center f-h-center full-width">
                        <PWIButton onClick={() => window.open(`${REACT_APP_ACCOUNT_URL}/${language}/forums/pwi`)} style={{ marginBottom: 80 }} className={windowDimensions.width > 600 ? "PWI-btn-class" : "PWI-btn-smaller-class"}>
                            <Typography variant="button" >
                                {t("NewsDetails.DiscussOnForums")}
                            </Typography>
                        </PWIButton>
                        <div className="social-media-container full-width flex f-v-center">
                            <Typography style={{ fontWeight: 300, textTransform: 'uppercase' }} variant="h6">
                                {t("news.details.share")}:
                            </Typography>
                            <FacebookShareButton url={window.location.href} className="flex f-v-center"><span title="Facebook"><FaceBookBlackIcon className="social-icon" /></span></FacebookShareButton>
                            <TwitterShareButton url={window.location.href} className="flex f-v-center"><span title="Twitter"><TweeterIcon className="social-icon" /></span></TwitterShareButton>
                        </div>
                    </div>
                </Container>
            </div>
            {!loading && relatedNews.length > 0 && 
                <div className="related-news-container flex f-h-center f-v-center">
                    <img className="footer-divider" src={FooterDivider} alt="divider" />
                    <Container>
                        <div className="related-news-content-container">
                            <div className="related-news-header flex">
                                <HeaderIcon />
                                <Typography variant="h6">
                                    {t("NewsDetails.RELATEDNEWS")}
                                </Typography>
                            </div>
                            {loading && <div className="related-news-loading"><CircularProgress classes={{ root: "progress-root" }} /></div>}
                            {windowDimensions.width < 600 && relatedNews.length > 0 &&
                                <div className="flex f-v-center f-h-center related-news-cards">
                                    {relatedNews.slice(0, mobileNewsShown).map(newsItem => (
                                        <RelatedNews newsItem={newsItem} key={newsItem.id} getNewsArticleData={getNewsArticleData} />
                                    ))}
                                    {loading && <CircularProgress classes={{ root: "progress-root" }} />}
                                    {mobileNewsShown < relatedNews.length && <PWIButton onClick={() => setMobileNewsShown(newsShown => newsShown + 2)}>
                                        <Typography variant="button">
                                            {t("News.LOADMORE")}
                                        </Typography>
                                    </PWIButton>}
                                </div>
                            }
                            {windowDimensions.width >= 600 && relatedNews.length <= 2 &&
                                <div className="flex">
                                    {relatedNews.map(newsItem => <RelatedNews newsItem={newsItem} getNewsArticleData={getNewsArticleData} />)}
                                </div>
                            }
                            {windowDimensions.width >= 600 && relatedNews.length > 2 &&
                                <Carousel className="carousel-container"
                                    indicators
                                    showArrows={true}
                                    IndicatorIcon={<div />}
                                    navButtonsAlwaysVisible={true}
                                    indicatorIconButtonProps={{
                                        style: {
                                            display: "none",
                                            width: "62px",
                                            height: "8px",
                                            background: "white",
                                            marginLeft: '10px',    // 1
                                            color: 'blue',       // 3
                                            borderRadius: "10px"
                                        }
                                    }}
                                    activeIndicatorIconButtonProps={{
                                        style: {
                                            background: `${COLORS.SECONDARY}`,
                                        }
                                    }}
                                    indicatorContainerProps={{
                                        style: {
                                            display: "none",

                                            marginTop: '50px', // 5
                                            textAlign: 'center' // 4
                                        }

                                    }}
                                    setSelectedItems={2}
                                    autoPlay={false}
                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            backgroundColor: 'transparent',
                                            borderRadius: 0,
                                        },
                                    }}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0',
                                            top: 'unset',
                                            display: 'flex',
                                            marginBottom: "0.6rem"
                                        },
                                    }}
                                    NavButton={({ next, prev }) => {
                                        // Other logic
                                        return (
                                            <>
                                                {prev && <Button className="carousel-button" onClick={getPrev} onMouseEnter={leftArrowMouseInandler} onMouseLeave={leftArrowMouseOutHandler}>
                                                    <img src={leftArrowHover ? NextHoverIcon : NextIcon} alt="previous" />
                                                </Button>}
                                                {next && <Button className="carousel-button" onClick={getNext} onMouseEnter={rightArrowMouseInandler} onMouseLeave={rightArrowMouseOutHandler}>
                                                    <img style={{ transform: "rotate(180deg)" }} src={rightArrowHover ? NextHoverIcon : NextIcon} alt="next" />
                                                </Button>}
                                            </>
                                        )
                                    }}
                                >
                                    <div className="flex f-v-center f-h-center ">
                                        <div className="related-news-content flex" >
                                            <RelatedNews newsItem={relatedNews[relatedNewsIndex]} getNewsArticleData={getNewsArticleData} />
                                            {relatedNews[relatedNewsIndex + 1] && <RelatedNews newsItem={relatedNews[relatedNewsIndex + 1]} getNewsArticleData={getNewsArticleData} />}
                                        </div>
                                    </div>
                                </Carousel>
                            }
                        </div>
                    </Container>
                </div>
            }
        </NewsDetailsBodyWrapper>
    )
}
