import styled from "styled-components";
import { responsive } from "Styles/Constants";

export const CarousalContentWrapper = styled.div.attrs({
  className: 'CarousalContentWrapper',
})`
  display: flex;
  gap: 2rem;
  background-color: ${(props) => (props.darkTheme ? "#151515" : "none")};
  border-radius: 0px 20px 20px 20px;
  width: 100%;
  ${responsive.TABLET`
      flex-direction: column;
      align-items: center;
      margin: 2rem auto 0;
      max-width: 600px;
  `}

  .image-container {
    display: flex;
    width: 50%;
    margin: 7px;

    ${responsive.TABLET`
      width: calc(100% - 20px);
    `}

    .container {
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      aspect-ratio: 16 / 9;
      height: auto;
    }

    .border-container {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: calc(100% + 14px);
      height: calc(100% + 12px);
      top: -6px;
      left: -7px;
    }
  }

  .header {
    color: #fff2e6;
    font-size: 32px;
  }

  .sub-txt {
    color: #d4c3b3;
    font-size: 18px;
  }

  .content-container {
    flex-direction: column;
    padding: 1rem;
    width: 50%;
    min-height: ${(props) => props.darkTheme && "300px"};
    ${responsive.TABLET`
      width: 100%;
    `}
  }
`;
