import { Container } from "Components/Website.style";
import { URL_CHARGE, URL_CHARGE_HISTORY } from "Helpers/Paths";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import { ChargeWrapper, LineWrapper } from "./Charge.style";
import Reward from "./ChargePromo";
import Instruction from "./Instruction";
import History from "./RedemptionHistory/History";
import Rewards from "./Rewards";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Api from "Helpers/ApiHandler";
import Timer from "react-compound-timer";
import { isEmpty } from "Helpers/Utils";
import useWindowSize from "Hooks/useWindowSize";
import moment from "moment-timezone";

function Charge(props) {
   const { t } = useTranslation();
   const windowSize = useWindowSize();
   const location = useLocation();
   const { lang } = useParams();
   const language = useSelector(state => state.Auth.lang);
   const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
   const [POINTS, setPoints] = useState(0);
   const [range, setRange] = useState({
      startRange: 0,
      endRange: 0,
      midRange: 0,
   });

   const [tiers, setTiers] = useState([]);
   const [campaignData, setCampaignData] = useState([]);
   const [rewardItems, setRewardItems] = useState([]);
   const [tab, setTab] = useState(1);
   const [claimTimer, setClaimTimer] = useState(0);
   const [transferTimer, setTransferTimer] = useState(0);
   const [noActiveCampaign, setNoActiveCampaign] = useState(false)
   const [serverCharacterArray, setServerCharacterArray] = useState({ servers: [], roles: [] });
   const [loadingData, setLoadingData] = useState(false);

   // for setting the stepper
   useEffect(() => {
      if (tiers.length) setStepperUI();
      // eslint-disable-next-line
   }, [POINTS]);

   // for setting reward items to the current tab
   useEffect(() => {
      if (tiers.length) {
         filterRewardsData(tab);
      }
      // eslint-disable-next-line
   }, [tiers])

   useEffect(() => {
      calculateTimers(campaignData);
      // eslint-disable-next-line
   }, [location.pathname]);

   // for setting initial values
   useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (isLoggedIn) {
         getCampaignData();
         getUserRoles();
      } else if (urlParams.get("jwt") !== null) {
         const api = new Api();
         api.get(`users/session`,
            {
               headers: { Authorization : `Bearer ${urlParams.get("jwt")}` },
               skipAuth: true, // Skip Auth is so api does not attempt to input an access token when we're using jwt
            }
         )
            .then(response => {
               if (response?.jwt) {
                  const getUserEvent = new CustomEvent('PWI::get_user_details', {
                     detail: {
                        token: `Bearer ${response.jwt}`,
                     }
                  });
                  window.dispatchEvent(getUserEvent);
               } else {
                  triggerSignIn();
               }
            }, (e) => {
               console.error("Rejected", e);
               triggerSignIn();
            })
            .catch(e => {
               console.log(e);
               triggerSignIn();
            })
            .finally(f => {});
      } else if (!urlParams.get('code')) { // If url param has code, wait for login success from tobar
         triggerSignIn();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isLoggedIn, lang]);

   const triggerSignIn = () => {
      const signInEvent = new CustomEvent('PWI::sign_in', {
         detail: {
            redirect: window.location.pathname
         }
      });
      window.dispatchEvent(signInEvent);
   };

   const getRpBalance = (campaign_id) => {
      const api = new Api();
      api.get(`chargerewards/pwi/points/${campaign_id}`)
         .then(response => {
            setPoints(response?.points || 0)
         })
         .catch(e => {
            console.log("Error fetching RP balance ", e);
         })
         .finally(f => {
            setLoadingData(false);
         });
   }

   const getCampaignData = () => {
      setLoadingData(true);
      const api = new Api();
      api.get(`chargerewards/pwi/campaigns?field[]=claimable&field[]=reward_id&field[]=times_redeemable&field[]=description&field[]=is_limited`)
         .then(response => {
            initializeComponent(response);
            getRpBalance(response[0].campaign_id);
         })
         .catch(e => {
            setNoActiveCampaign(true);
            setLoadingData(false);
            props.history.replace("/" + language + URL_CHARGE_HISTORY)
         })
   }

   const initializeComponent = (response) => {
      if (Array.isArray(response) && response.length > 0) {
         setCampaignData(response[0]);
         calculateTimers(response[0]);
         let tiersArray = response[0].tiers.map(item => {
            return { 
               id: parseInt(item.level),
               title: `${t(`charge.tier.${item.level}`)}`, 
               value: parseInt(item.cost)
            }
         });
         setTiers(tiersArray);
      }
   }

   const calculateTimers = (campData) => {

      let now = getPSTtime(new Date()).getTime();
      let startClaim = getPSTtime(campData.claim_start).getTime();
      let transStart = getPSTtime(campData.transfer_start).getTime();
      if (now > startClaim) {
         let claimEnd = getPSTtime(campData.claim_end).getTime();
         let claimTime = claimEnd - now;
         if (claimTime && claimTime > 0) {
            setClaimTimer(claimTime)
         }
      }

      if (now > transStart) {
         let tranEnd = getPSTtime(campData.transfer_end).getTime();
         let tranTime = tranEnd - now;
         if (tranTime && tranTime > 0) {
            setTransferTimer(tranTime)
         }
      }

   }

   const getPSTtime = (date) => {
      return moment.tz(date, "America/Los_Angeles").toDate();
   }

   const getUserRoles = async () => {
      new Api().get(`users/me?field[]=roles`)
         .then(response => {
            if (Array.isArray(response.roles)) {
               // Get array of unique servers
               const serverArray = [...new Map(response.roles.map(item => [item["server"]["id"], item["server"]])).values()];
               setServerCharacterArray({ servers: serverArray, characters: response.roles });
            }
         })
         .catch(e => {
            console.log("Error fetching user roles ", e)
         })
   }

   const setStepperUI = () => {
      let stepper = tiers.map((item) => item.value);

      let closest;

      stepper.forEach((item, index) => {
         if (POINTS >= item && POINTS <= stepper[index + 1]) {
            closest = item;
         }
      });

      let start = closest < POINTS ? closest : 0;

      let closedIndex = stepper.findIndex((item) => item === start);

      let tryToFindPoint = stepper.findIndex((item) => item === POINTS);

      let end = tryToFindPoint >= 0 ? stepper[tryToFindPoint] : stepper[closedIndex + 1];

      if (POINTS <= start) end = stepper[closedIndex];

      let needToConvert = POINTS - start;
      let fullPercent = end - start;
      let percentage = (needToConvert * 100) / fullPercent;

      if (percentage > 100) {
         setRange({
            startRange: stepper[stepper.length - 2],
            endRange: stepper[stepper.length - 1],
            midRange: 100
         })
         return;
      }

      setRange({
         startRange: start,
         endRange: end,
         midRange: percentage,
      });
   }

   const filterRewardsData = (currentTab) => {
      const selectedTab = tiers.find(tier => tier.id === currentTab);
      const prevTab = tiers.find(tier => tier.id === currentTab - 1) || null;

      const upperLimit = selectedTab.value;
      const lowerLimit = prevTab?.value || 0;

      if (!isEmpty(selectedTab)) {
         const sortedRewardItems = campaignData?.items?.sort((a, b) => a.cost - b.cost);
         const rewardArray = sortedRewardItems?.filter(item => {
            return parseInt(item.cost) > lowerLimit && parseInt(item.cost) <= upperLimit
         });
         setRewardItems(rewardArray);
      }
   }

   const updateTab = (selectedTab) => {
      filterRewardsData(selectedTab);
      if (window.innerWidth <= BREAKPOINTS_VALUE.PHABLET && tab === selectedTab) {
         return setTab(null);
      }
      setTab(selectedTab);
   };

   return (
      <ChargeWrapper>
         <div id="scroll-to-me" />
         <div className="bg-image" />

         <div className="container">
            <Container className="card-container">
               <div className="card">
                  <Reward expire_promo={noActiveCampaign} claimTimer={transferTimer} />

                  {location.pathname === "/" + language + URL_CHARGE_HISTORY && (
                     <div className="content">
                        <History expire_promo={noActiveCampaign} campaignId={campaignData.campaign_id} />
                     </div>
                  )}

                  {location.pathname === "/" + language + URL_CHARGE && (
                     <div className="content">
                        <div className="status"><strong>{t('charge.reward.point.status')}</strong></div>
                        <div className="header-section">
                           {claimTimer > 0 && (<Timer
                              initialTime={claimTimer}
                              direction="backward"
                           >
                              <div className="timing" >
                                 <div>{t("charge.remaining.time.spend")}</div>
                                 <span className="number"><strong><Timer.Days /></strong></span>{t("Charge.day")}
                                 <span className="number"><strong><Timer.Hours /></strong></span>{t("Charge.HOURS")}
                                 <span className="number"><strong><Timer.Minutes /></strong></span>{t("Charge.MINS")}
                                 <span className="number"><strong><Timer.Seconds /></strong></span>{t("Charge.SECS")}

                              </div>
                           </Timer>)}
                           <div className="status-balance">
                              <div
                                 className="redemption-history"
                                 onClick={() => props.history.push("/" + language + URL_CHARGE_HISTORY)}
                              >
                                 <strong>
                                    {t("charge.dialog.view.redemption.history")}
                                 </strong>
                              </div>
                              <div className="balance">
                                 {`${t("Charge.RPBalance")}: `}
                                 <span className="points">{POINTS}</span>
                              </div>
                           </div>
                        </div>

                        <div className="steeper">
                           {tiers.map((item) => (
                              <div key={item.id} className="main">
                                 <LineWrapper
                                    width={
                                       item.value === range.endRange &&
                                       POINTS > range.startRange &&
                                       POINTS < range.endRange &&
                                       range.midRange
                                    }
                                    className={`dummy-line ${item.value <= range.endRange && "active"} 
                                    ${item.id === tiers.length && POINTS > range.endRange && "active"}`}
                                 />
                                 <div className="dummy-square">
                                    <div
                                       className={`square ${POINTS >= item.value && "active"}`}
                                    />
                                    <div className="rp">{t(`${item.value}`)} {t('milestone.RP')}</div>
                                 </div>
                              </div>
                           ))}
                        </div>

                        <div className="rewards">
                           <div className="heading">{t("charge.rewards")}</div>
                           <div className="tabs">
                              {tiers.map((item) => (
                                 <React.Fragment>
                                    <div
                                       className={`tab ${tab === item.id && "active"}`}
                                       key={item.id}
                                       onClick={() => updateTab(item.id)}
                                    >
                                       {windowSize.width < BREAKPOINTS_VALUE.PHABLET && (
                                          <span className="cross-icon disabled" />
                                       )}
                                       {item.title}
                                       {windowSize.width < BREAKPOINTS_VALUE.PHABLET && (
                                          <span
                                             className={`cross-icon ${tab === item.id && "active"
                                                }`}
                                          />
                                       )}
                                    </div>
                                    {windowSize.width < BREAKPOINTS_VALUE.PHABLET &&
                                       tab === item.id && 
                                       <Rewards 
                                          rewardItems={rewardItems}
                                          campaignId={campaignData.campaign_id}
                                          serverCharacterArray={serverCharacterArray}
                                          getCampaignData={getCampaignData}
                                          loadingData={loadingData}
                                       />
                                    }
                                 </React.Fragment>
                              ))}
                              <div className="dummy-tab-line" />
                           </div>

                           {windowSize.width >= BREAKPOINTS_VALUE.PHABLET && (
                              <Rewards 
                                 rewardItems={rewardItems}
                                 campaignId={campaignData.campaign_id}
                                 serverCharacterArray={serverCharacterArray}
                                 getCampaignData={getCampaignData}
                                 loadingData={loadingData}
                              />
                           )}
                           <Instruction />
                        </div>
                     </div>
                  )}
               </div>
            </Container>
         </div>
      </ChargeWrapper>
   );
}

export default Charge;
