import styled from "styled-components";
import BackgroundImage from "Assets/PageNotFound/background.png"
import BackgroundImage980 from "Assets/PageNotFound/background_980.png"
import BackgroundImage320 from "Assets/PageNotFound/background_320.png"
import ButtonBackground from "Assets/PageNotFound/button_background.png"
import { responsive } from "Styles/Constants";

export const PageNotFoundContainer = styled.div.attrs({
    className: 'PageNotFoundContainer',
  })`
    background-image: url(${BackgroundImage});
    height: 1130px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top;

    ${responsive.TABLET`
        background-image: url(${BackgroundImage980})
    `}

    /* ${responsive.PHABLET`
        background-image: url(${BackgroundImage320})
    `} */
    
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;
    }

    .page-404{
        display: flex; 
        flex-direction: row; 
        align-items: baseline;
    }

    .page-404-font{
        font-size: 100px;
    }

    .description{
        width: 450px; 
        text-align: center;

        ${responsive.PHABLET`
            width: 400px;
        `}
    }

    .description-font{
        font-size: 26px;
    }

    .return-homepage{
        background-image: url(${ButtonBackground});
        width: 264px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
    }

    .return-homepage-font{
        color: white;
    }
`