import styled from "styled-components";
import BackgroundImage from "Assets/Charge/header-ChargeReward.png";
import BG_Texture from "Assets/newscontent_bg.png";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import CrossIcon from "Assets/Charge/PluseIcon.svg";
import MinusIcon from "Assets/Charge/minus.svg";

export const ChargeWrapper = styled.div.attrs({
  className: 'ChargeWrapper',
})`
  .bg-image {
    background-image: url(${BackgroundImage});
    height: 40vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${responsive.PHABLET`
            max-height : 133px;
        `}
  }

  .container {
    background-image: url(${BG_Texture});
    height: max-content;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .card-container {
      ${responsive.TABLET`
                width: 100%;
            `}
    }

    .card {
      height: max-content;
      width: 100%;
      margin: -200px auto 100px auto;
      filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.16));
      /* border: 4px solid #c0a371; */
      /* background-color: #F3F2F2; */
      max-width: 1200px;
      background-color: #ebe6e2;
      ${responsive.PHABLET`
                margin : -50px 0 100px;
            `}
      .content {
        width: 90%;
        height: max-content;
        margin: 0 auto;

        .status {
          font-size: 20px;
          font-family: ${FONTS.SECONDARY_BOLD};
          text-transform: uppercase;
          font-weight: 600;
          margin: 1rem 0;
        }
        .header-section {
          display: flex;
          justify-content: space-between;
          ${responsive.TABLET`
            font-size: 16px;
          `}
          ${responsive.PHABLET`
            flex-direction: column;
            gap: 1rem;
            align-items: center;
          `}
        }

          .redemption-history {
            margin: 0 0 0 auto;
            text-decoration: underline;
            font-size: 16px;
            font-family: ${FONTS.SECONDARY_BOLD};
            color: #321a19;
            margin-bottom: 10px;
            ${responsive.TABLET`
                font-size: 12px;
            `}

            &:hover {
              cursor: pointer;
            }
          }

          .status-balance {
            display: flex;
            flex-direction: column;
            text-align: center;

            .balance {
              font-size: 18px;
              font-family: ${FONTS.SECONDARY_BOLD};
              margin: 0 0 0 auto;
              color: #321a19;
              font-weight: 600;
              text-align: center;
              ${responsive.PHABLET`
                                margin: 0;
                            `}
              .points {
                color: #c85000;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
              }
            }
          }

          .timing {
            font-size: 16px;
            font-family: ${FONTS.SECONDARY};
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #321a19;
            .number {
              font-family: ${FONTS.SECONDARY_BOLD};
              margin: 0 5px;
              font-size: 18px;
              text-transform: capitalize;
            }
          }
        }

        .steeper {
          display: flex;
          margin: 50px 0 0;

          .main {
            width: 100%;
            display: flex;
            align-items: center;

            .dummy-square {
              position: relative;
              .square {
                width: 12px;
                height: 12px;
                transform: rotate(135deg);
                background: transparent;
                border: 3px solid #aaaaaa;

                &.active {
                  box-shadow: 0px 0px 6px #6e6c6a;
                  border-color: #ffffff;
                  background: #c85000;
                }
              }
              .rp {
                position: absolute;
                width: max-content;
                top: -26px;
                margin: 0 -20px;
              }
            }

            &:last-of-type {
              .rp {
                right: 0;
                margin: 0;
              }
            }
          }
          ${responsive.PHABLET`
                        display: none
                    `}
        }

        .rewards {
          margin: 50px 0;

          .heading {
            font-size: 20px;
            font-family: ${FONTS.PRIMARY_BOLD};
            text-transform: uppercase;
          }

          .tabs {
            display: flex;
            align-items: flex-end;
            margin: 30px 0;

            ${responsive.PHABLET`
                            flex-direction : column;
                        `}
            .tab {
              min-width: 125px;
              height: 40px;
              font-size: 14px;
              font-family: ${FONTS.PRIMARY_BOLD};
              letter-spacing: 1px;
              color: #321a19;
              font-weight: 800;
              border: 1px solid #aea494;
              background-color: #efe8dc;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              &.active {
                z-index: 10;
                position: relative;
                background-color: #ebe6e2;
                color: #321a19;
                border-top-color: #61481d;
                border-left-color: #61481d;
                border-right-color: #61481d;
                border-bottom-color: transparent;

                ${responsive.PHABLET`
                  border-bottom-width: 2px;
                  border-bottom-color: rgb(227, 211, 182);
                `}
              }

              &:hover {
                color: #321a19;
                cursor: pointer;
              }

              ${responsive.TABLET`
                min-width: 80px;
                justify-content: center;
              `}
              ${responsive.PHABLET`
                width: 100%;
                justify-content: space-between;
              `}
              .cross-icon {
                height: 12px;
                width: 12px;
                background-image: url(${CrossIcon});
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin: 0 10px 0 0;

                &.active {
                  background-image: url(${MinusIcon});
                }
                &.disabled {
                  opacity: 0;
                }
              }
            }
          }
          .dummy-tab-line {
            width: 100%;
            border: 1px solid #c9b189;

            ${responsive.PHABLET`
                display : none;
            `}
          }
        }
      }
    }
  }
`;

export const LineWrapper = styled.div.attrs({
  className: 'LineWrapper',
})`
  &.dummy-line {
    height: 4px;
    width: 100%;
    background: #aaa;

    &.active {
      box-shadow: ${(props) =>
        props.width ? "none" : `0px 0px 6px ${COLORS.SECONDARY_LIGHT}`};
      /* background: #008b05; */
      background: ${(props) =>
        props.width
          ? `linear-gradient(90deg, #9b0200 ${props.width}%, #AAA ${props.width}%)`
          : "#9b0200"};
      background-repeat: no-repeat;
    }
  }
`;
