export const ACTION_TYPES = {
    TOAST_MESSAGE_ENABLE        : "TOAST_MESSAGE_ENABLE",
    TOAST_MESSAGE_DISABLE       : "TOAST_MESSAGE_DISABLE"
}

export const ToastType = {
    SUCCESS : "Success",
    ERROR: "Error",
    NONE: ""
}

export const showToast = (message, type = ToastType.SUCCESS) => {
    return {
        type    : ACTION_TYPES.TOAST_MESSAGE_ENABLE,
        message : message || '',
        toastType: type
    }
}
export const hideToast = () => {
    return {
        type    : ACTION_TYPES.TOAST_MESSAGE_DISABLE
    }
}

