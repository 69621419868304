import styled from "styled-components";
// import { COLORS, FONTS } from "Styles/Constants";

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export const SnackbarStyle = styled(Snackbar).attrs({
    className: 'Snackbar',
})`
    top: calc(12px + var(--default-header-height)) !important;
`;

export const SnackbarContentWrapper = styled(SnackbarContent).attrs({
    className: 'SnackbarContentWrapper',
})`
    &.themed {
        background-color: #fff;
        padding-left    : 30px;

        .notification-message {
            color       : #000;
            font-size   : 15px;
            max-width   : calc(100% - 75px);
        }

        .close-icon {
            color : #a4afb7;
        }
    }

    &.success-toast {
        .notification-message {
            color: #4CAF50;
        }
    }

    &.error-toast {
        .notification-message {
            color: #F44336;
        }
    }
`