import styled from "styled-components"
import { FONTS } from "Styles/Constants"

export const InstructionWrapper = styled.div.attrs({
    className: 'InstructionWrapper',
  })`
    margin : 50px 0;

    .title {
        color : #614F4F;
        font-size: 16px;
        font-family: ${FONTS.SECONDARY_BOLD};
        font-style: italic;
    }

    .step {
        margin: 5px 0;
        color : #614F4F;
        font-size: 16px;
        line-height: 24px;
        font-family: ${FONTS.SECONDARY};
    }
`