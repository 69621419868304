import { ACTION_TYPES } from './Actions';

const initState = {
    isLoggedIn: localStorage.getItem('isLoggedIn') ? (localStorage.getItem('isLoggedIn') === "true") : false,
    accessToken: localStorage.getItem('accessToken') || "",
    lang: localStorage.getItem('lang') || "en",
    codeVerifier: localStorage.getItem('codeVerifier') || "",
    profileInfo: localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')) : {},
    totalScore: 0,
    userIP: "",
    isGdpr : localStorage.getItem("isGdpr")
      ? (localStorage.getItem("isGdpr") === 'true')
      : true, // Default to true for gdpr since it's safer to show it in case of error
    isInternal : localStorage.getItem("isInternal")
      ? (localStorage.getItem("isInternal") === 'true')
      : false,
    regionOverwrite : ""
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:
            return {
                ...state,
                isLoggedIn  : true,
                accessToken: action.accessToken
            };

        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...state,
                isLoggedIn: false
            };
        case ACTION_TYPES.CODE_VERIFIER:
            return {
                ...state,
                codeVerifier: action.codeVerifier
            };
        case ACTION_TYPES.ADD_LANG:
            return {
                ...state,
                lang: action.lang
            };
        case ACTION_TYPES.UPDATE_LANG:
            return {
                ...state,
                lang: action.lang
            };
        case ACTION_TYPES.UPDATE_PROFILE:
            return {
                ...state,
                profileInfo: action.profileInfo
            };
        case ACTION_TYPES.USER_IP:
            return {
                ...state,
                userIP: action.userIP,
            };
    
        case ACTION_TYPES.SET_IS_GDPR:
            return {
                ...state,
                isGdpr : action.isGdpr
            };

        case ACTION_TYPES.SET_IS_INTERNAL:
            return {
                ...state,
                isInternal : action.isInternal
            };

        case ACTION_TYPES.SET_REGION_OVERWRITE:
            return {
                ...state,
                regionOverwrite : action.regionOverwrite
            };

        
        default:
            return state;
    }
    
}

export default Reducer;