import React from "react";
import { InstructionWrapper } from "./Instruction.style";
import { useTranslation } from "react-i18next";

function Instruction() {
    const { t } = useTranslation();
    return (
        <InstructionWrapper>
            <div className="title">{t("Charge.RedemptionInstruction")}</div>
            <div className="step">{t("Charge.step1")}</div>
            <div className="step">{t("Charge.step2")}</div>
            <div className="step">{t("Charge.step3")}</div>
            <div className="step">{t("Charge.step4")}</div>
            <div className="step">{t("Charge.step5")}</div>
            <div className="step">{t("Charge.step6")}</div>
            <br />
            <div className="title">{t("charge.note.title")}</div>
            <div className="step">{t("charge.note.description")}</div>
        </InstructionWrapper>
    )
}

export default Instruction;