import React from 'react';
import { ButtonWrapper } from './Button.style';

const Button = (props) => {
    const { onClick, children, className, style, disabled } = props;
    return (
        <ButtonWrapper >
            <button disabled={disabled} style={style} onClick={onClick} className={className || "default-class"}>
                {children}
            </button>
        </ButtonWrapper>
    );
};

export default Button;