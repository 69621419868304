import React from "react";
import DashboardWrapper from "./Home.style";
import BackgroungVideo from "../../Assets/Videos/08102021_PWI_Animated-Header_H264_compressed.mp4";
import PWILogo from "../../Assets/Group 1285.svg";
import SecondBannerItem from '../../Assets/2nd_banner_item1.png';
import BottomBlurImg from '../../Assets/Rectangle 319.svg'
import AboutIcon from '../../Assets/about_section.svg';
import ThirdBannerItem1 from '../../Assets/3rd_banner_item1.png'
import ThirdBannerItem2 from '../../Assets/3rd_banner_item2.png';
import FourthBannerItem from '../../Assets/4th_banner_item.png';
import FifthBannerItem1 from '../../Assets/5th_banner_item1.png'
import FifthBannerItem2 from '../../Assets/5th_banner_item2.png';

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

import Typography from "@material-ui/core/Typography";

function Home() {

  const { t } = useTranslation();
  const history = useHistory();
  const language = useSelector(state => state.Auth.lang);
  const { REACT_APP_ACCOUNT_URL } = process.env;

  const playNowClickHandler = () => {
    window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=pwi`);
    history.push(`/${language}/success`)
  }

  const learnMoreClickHandler = () => {
    history.push(`/${language}/news`);
  }

  return (
    <DashboardWrapper>
      <div id="scroll-to-me" />
      <section className="video-section">
        <video src={BackgroungVideo} autoPlay muted loop>
          <source type="video/mp4" />
        </video>
        <div className="save-world-container">
          <div className="logo-container">
            <img src={PWILogo} alt="LOGO" />
          </div>
          <div className="message-container">
            <Typography variant="h3">
              {
                t("Home.ProtectWorldMessage")
              }
              {/* PROTECT A VAST WORLD <br />
              FROM DESTRUCTION */}
            </Typography>
          </div>
          <div className="play-now-container">
            <button onClick={playNowClickHandler} className="playnow-btn">
              <Typography variant="button">{t("Home.PLAYNOW")}</Typography>
            </button>
          </div>
        </div>
      </section>
      <section className="second-banner-section">
        <img className="feature-img" src={SecondBannerItem} alt="FEATURE" />
        <div className="learn-more-container">
          <div className="learn-more-header">
            <Typography variant="h5">
              {t("Home.ProtectWorldMessage")}!
            </Typography>
          </div>
          <div className="learn-more-body" >
            <Typography variant="subtitle1">
              {t("Home.speadCorruptionMessage")} {" "} {t("Home.sunRisesMessage")}
            </Typography>
            <div className="learn-more-btn-container">
              <button onClick={learnMoreClickHandler} className="learn-more-btn">
                <Typography variant="button">{t("Home.LearnMore")}</Typography>
              </button>
            </div>
          </div>
        </div>
        <img className="blur-bar" src={BottomBlurImg} alt="" />
      </section>
      <section className="third-banner-section">
        <div className="content-container">
          <div className="content-header">
            <img src={AboutIcon} alt="ABOUT" />
            <div>
              <Typography variant="h5" className="about-title">
                {t("Home.ABOUT")}
              </Typography>
            </div>
            <div className="about-text-container">
              <Typography variant="subtitle2" >
                {t("Home.AboutMessage")}
              </Typography>
            </div>
          </div>
        </div>
        <div className="images-container">
          <img src={ThirdBannerItem1} alt="IMAGE1" />
          <img src={ThirdBannerItem2} alt="IMAGE2" />
        </div>
      </section>
      <section className="fourth-banner-section" >
        <div className="container">
          <div className="content-container" >
            <img src={AboutIcon} alt="" />
            <div className="content-header">
              <Typography variant="h5" className="text-header">
                {/* CROSS SERVER PVP */}
                {t("Home.CrossOverHeader")}
              </Typography>
              <Typography variant="subtitle2" align="center">
                {/* Faction battles, featuring up to 80 vs 80 players, against rival factions to claim in-game territory and gain untold riches. */}
                {t("Home.CrossOverMessage")}
              </Typography>
            </div>
          </div>
        </div>
        <img className="fourth-banner-item" src={FourthBannerItem} alt="" />
      </section>
      <section className="fifth-banner-section" >
        <img className="banner-item-1" src={FifthBannerItem1} alt="MAN" />
        <div className="content-container" >
          <div className="header-container">
            <img src={AboutIcon} alt="" />
            <Typography variant="h5" className="text-header">
              {/* CREATE THE PERFECT CHARACTER */}
              {t("Home.createCharHeader")}
            </Typography>
            <Typography variant="subtitle2" align="center" >
              {/* Customize to your heart’s desire with a deep character creation system. Choose among 6 Unique Races with 12 Distinct Classes to best fit your playstyle. */}
              {t("Home.CreateCharMessage")}
            </Typography>
          </div>
          <div className="image-container" >
            <img src={FifthBannerItem2} alt="" />
          </div>
        </div>
        <div className="fifth-banner-mobile-view" >
          <div className="mobile-content-container" >
            <div className="header-container">
              <img src={AboutIcon} alt="" />
              <Typography variant="h5" className="text-header">
                {/* CREATE THE PERFECT CHARACTER */}
                {t("Home.createCharHeader")}
              </Typography>
              <Typography variant="subtitle2" align="center" >
                {/* Customize to your heart’s desire with a deep character creation system. Choose among 6 Unique Races with 12 Distinct Classes to best fit your playstyle. */}
                {t("Home.CreateCharMessage")}
              </Typography>
            </div>
            <img className="mobile-banner-item-1" src={FifthBannerItem1} alt="MAN" />
            <img src={FifthBannerItem2} alt="" className="mobile-banner-item-2" />
          </div>
        </div>
      </section>
    </DashboardWrapper>
  );
}

export default Home;