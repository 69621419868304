import styled from "styled-components";
import { COLORS } from "Styles/Constants";

export const Container = styled.div.attrs({
  className: 'Container',
})`
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;

  .social-icon {
    &:hover {
      fill: ${COLORS.SECONDARY};
    }
  }
`;
