import React, { useState } from 'react';
import { RewardsWrapper } from './Rewards.style';
import ChargePromoItem from "Assets/Charge/charge-promo-item.png";
import PWIButton from 'Components/Commons/Button/Button'
import RedeemRewards from './Dialog/RedeemDialog';
import { useTranslation } from 'react-i18next';

function Rewards(props) {
    const { t: translate } = useTranslation();
    const { rewardItems, serverCharacterArray, campaignId, getCampaignData, loadingCampaignData } = props;
    const [dialog, setDialog] = useState({
        open: false,
        data: {}
    })

    const setStatusOfDialog = (item) => {
        setDialog({
            open: true,
            data: item
        })
    }

    const closeDialog = () => {
        setDialog({
            open: false,
            data: {}
        })
    }

    return (
        <RewardsWrapper>
            {!loadingCampaignData && rewardItems.map((item) =>
                <div className="reward-list-card" key={item.id}>
                    <div className="image-container">
                        <div className="image" style={{ backgroundImage: `url(${item.image || ChargePromoItem})` }} />
                        {item?.is_limited && <div class="limited-badge">{translate('charge.limited')}</div>}
                    </div>
                    <div className="reward-container">
                        <div className="reward-text">
                            <div className="title">{item.title || item.name}</div>
                            <div className="key">{translate('reward.quantity')} <strong> <span className="quantity-number">{item.quantity}</span></strong></div>
                            {item?.is_limited && <div className="key">{translate('reward.redeemable')} <span className="quantity-number"><strong>{item.times_redeemable}</strong></span></div>}
                            <div className="key">{translate('reward.cost')} <strong><span className="quantity-number">{item.cost}</span></strong> RP</div>
                            <div className="description" dangerouslySetInnerHTML={{ __html: item.description || item.item_description }}></div>
                        </div>
                        {item?.claimable && <PWIButton disabled={false} onClick={() => setStatusOfDialog(item)}>{translate("charge.dialog.confirm")}</PWIButton>}
                        {!item?.claimable && 
                            <div className="tooltip">
                                <PWIButton disabled={true} onClick={() => setStatusOfDialog(item)}>{translate("charge.dialog.confirm")}
                                    <div className="tooltip-box">
                                        {item.is_limited && item.times_redeemable === 0 ? translate('charge.claim.tooltip.limited') : translate('charge.claim.tooltip.points')}
                                        <i></i>
                                    </div>
                                </PWIButton>
                            </div>
                        }
                    </div>
                </div>
            )}
            {dialog.open && <RedeemRewards data={dialog.data} campaignId={campaignId} open={dialog.open} onClose={closeDialog} serverCharacterArray={serverCharacterArray} getCampaignData={getCampaignData} />}
        </RewardsWrapper>
    )
}

export default Rewards;

// eslint-disable-next-line
const REWARDS = [
    {
        id: 1,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 2,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "Can be exchanged at Otis Horkennan in Nightfall Citadel for many different useful items"
    },
    {
        id: 3,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 4,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 5,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 6,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 7,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 8,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 9,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
    {
        id: 10,
        image: ChargePromoItem,
        title: "Mystic Lv4 Rune Pack",
        quantity: 1,
        times_redeemable: 5,
        cost: 1800,
        description: "This pack contains 1 random Lv4 Rune"
    },
]
