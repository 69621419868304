import React from 'react';
import { BackToTop } from './Footer.style';
import UpArrowIcon from 'Assets/Icons/UpArrowIcon';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  Social,
  FooterWrapper,
  FooterLogos,
  FooterLegal,
  FooterLinks,
} from "@fe-packages/components-and-utilities";

const companyLogos = ['arcgames', 'rating'];
const platformLogos = ['arc'];

const Footer = () => {
  const language = useSelector(state => state.Auth.lang);
  const userIP = useSelector(state => state.Auth.userIP);
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const {
    REACT_APP_API_URL,
    REACT_APP_ACCOUNT_URL,
    REACT_APP_CORP_URL,
    REACT_APP_SITE_GAME_ABBR,
  } = process.env;

  const scrollToTop = () => {
    var elmnt = document.getElementById("scroll-to-me");
    elmnt && elmnt.scrollIntoView({ behavior: "smooth" });
  };

  /* eslint-disable */
  return (<>
    <BackToTop onClick={scrollToTop}>
      <UpArrowIcon className="up-arrow" />
    </BackToTop>
    <Social lang={language} apiBaseUrl={REACT_APP_API_URL} micrositeGame={REACT_APP_SITE_GAME_ABBR} />
    <FooterWrapper>
      <FooterLogos lang={language} userip={userIP} apiBaseUrl={REACT_APP_API_URL} micrositeGame={REACT_APP_SITE_GAME_ABBR} companyLogos={companyLogos} platformLogos={platformLogos} />
      <FooterLegal legalLines={[
        <>
          {t('footer.legals', { year: currentYear })}
          <Trans i18nKey="footer.legals.embracer">
            We are part of the <a href="https://embracer.com/" target="_blank" rel="noopener noreferrer">Embracer Group</a>.
          </Trans>
        </>
      ]} />
      <FooterLinks accountBaseUrl={REACT_APP_ACCOUNT_URL} corporateUrl={REACT_APP_CORP_URL} lang={language} additionalElements={
        <div className="footer-section__link-full-wrap">
          <a className="footer-section__link" href="#" onClick={() => { OneTrust.ToggleInfoDisplay(); }} >{t('Cookie Preferences')}</a>
          <span className="footer-section__link-separator"></span>
          <a className="footer-section__link" href="#" onClick={() => { OneTrust.ToggleInfoDisplay(); }} >{t('Do not sell or share my personal information')}</a>
        </div>}/>
    </FooterWrapper>
  </>);
  /* eslint-enable */
}

export default Footer;
