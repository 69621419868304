import styled from "styled-components";
import { responsive } from "Styles/Constants";
import BG_Texture from 'Assets/newscontent_bg.png'

export const BodyContentWrapper = styled.div.attrs({
    className: 'BodyContentWrapper',
  })`
    display: flex;
    width: 100%;
    background-image: ${(props) => props.darkTheme ? "none": `url("${BG_Texture}")`}; 
    background-position: center top;
    background-size: 100%;
    background-repeat: repeat-y;
    color: ${(props) => props.darkTheme && "#fff"};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${responsive.TABLET`
        background-size: cover;
    `}
    .content-container {
        width: 100%;
        max-width: 1200px;
        padding: 0 28px;
        box-sizing: border-box;
        flex-direction: column;

        .header-title svg path {
            fill: #B29271;
        }

        .header-title {
            display: flex;
            gap: 1rem;
            margin-top: 60px;
            margin-bottom: 25px;
        }

        .header-filter-select {
            text-transform: uppercase;
        }

        .header-filter {
            margin-left: -16px;
            display: flex;
            align-items: center;
            
            .MuiButton-root {
                background-color: transparent;
                &:hover {
                    .btn-label {
                        border-bottom: 1px solid;
                    }
                }
            }
            .btn-label {
                font-size: 18px;
                border-bottom: 1px solid transparent;
                font-weight: 400;
                color: ${(props) => props.darkTheme ? "#fff" : "black"};
            }
            .btn-label.active {
                border-bottom: 1px solid;
            }
            strong {
                color: #b29271;
            }
        }

        .body-sub-header-select {
                /* margin: 10px;
                max-width: 280px; */
            }


    }
    .news-card-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0;
        width: 100%;
        max-width: 1200px;
        padding: 0 28px;
        box-sizing: border-box;
        ${responsive.PHABLET`
            margin-top: 0;
        `}
    }

    .load-more-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3rem;
    }
`;