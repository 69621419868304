import styled from "styled-components";
import PlayNowBtn from "../../Assets/Component 27 – 1 default.svg";
import PlayNowBtnHover from "../../Assets/Component 27 – 1.svg";
import { responsive } from "Styles/Constants";
import FirstBannerImage from "Assets/1st_banner_mobile.png";
import SecondBannerImage from "Assets/2nd_banner.png";
import ThirdBannerImage from "Assets/3rd_banner.png";
import FourthBannerImage from "Assets/4th_banner.png";
import FifthBannerImage from "Assets/5th_banner.png";
import LearnMoreBtnDefault from "Assets/Component 28 – 1 default.svg";
import LearnMoreBtn from "Assets/Component 28 – 1.svg";

const DashboardWrapper = styled.div.attrs({
  className: 'DashboardWrapper',
})`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .text-header {
    text-transform: uppercase;
  }

  .blur-bar {
    width: 100vw;
    position: "absolute";
    bottom: 0;
  }

  .content-container {
    word-break: break-word;
    .header-container {
      text-align: center;
      
      h5 {
        font-size: 32px;
        ${responsive.PHABLET`
          font-size: 28px;
        `}
      }
      .MuiTypography-subtitle2 {
        font-size: 16px;
      }
    }
  }

  .video-section {
    min-height: 789px;
    position: relative;

    ${responsive.TABLET`
      min-height: 581px;
    `}

    @media only screen and (max-width: 1440px) and (min-width: 1191px) {
      min-height: 650px;
    }

    @media only screen and (max-width: 1191px) and (min-width: 980px) {
      min-height: 575px;
    }

    @media only screen and (max-width: 901px) {
      background: url("${FirstBannerImage}") no-repeat;
      min-height: 581px;
      background-position: center;
      background-size: cover;
    }
    ${responsive.PHABLET`
      min-height: 420px;
    `}
    video {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      ${responsive.TABLET`
        object-fit: cover;
        min-height: 100%;
      `}
      /* @media only screen and (max-width: 1300px)  and (min-width: 981px) {
      min-height: 100%;
    } */
      
      @media only screen and (max-width: 901px) {
        width: 111%;
      }
      /* 901px */
      @media only screen and (max-width: 730px) {
        display: none;
        width: 100%;
      }
      /* ${responsive.PHABLET`display: none`} */
    }

    .save-world-container {
      & > div {
        margin-left: 5px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 100%;
      top: 19%;
      font-family: "Merriweather Sans";
      text-transform: uppercase;
      ${responsive.PHABLET`
        top: 4%;
      `}
      .logo-container {
        img {
          width: 188px;
          height: 196px;
        }
      }
      .message-container {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .MuiTypography-h3 {
          font-size: 36px;
          font-weight: 600;
          text-align: center;
          width: 75%;
          margin: auto;
          max-width: 700px;
          ${responsive.TABLET`
            font-size: 32px;
          `}
          ${responsive.PHABLET`
            font-size: 24px;
          `}
          @media only screen and (max-width: 400px) {
            width: 95%;
          }
        }
      }
      .play-now-container {
        .playnow-btn {
          border: none;
          margin: auto;
          display: block;
          width: 240px;
          height: 59px;
          background: url("${PlayNowBtn}") no-repeat;
          .MuiTypography-button {
            color: #fff1e4;
            font-weight: 400;
            font-size: 24px;
          }
          position: relative;
          transition: 200ms ease-in-out all;
          &:hover {
            background: url("${PlayNowBtnHover}") no-repeat;
            transform: translateY(-3px);
            cursor: pointer;
          }
          // Preload images and prevent the 'flash' on first hover
          &:after {
              position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
              content: url("${PlayNowBtnHover}"); // load images
          }
        }
      }
    }
  }
  .second-banner-section {
    background-image: url("${SecondBannerImage}");
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 360px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    ${responsive.TABLET`max-height: 417px;`}
    ${responsive.PHABLET`
            flex-direction: column-reverse;
            max-height: none;
            overflow: hidden;
        `}
        .feature-img {
      position: relative;
      bottom: 77px;
      height: 100%;
      ${responsive.LAPTOP`margin-left: 5%; 
                width: 583px;
                bottom: 35px;
                height: 469px;
                `}
      ${responsive.PHABLET`
        width: 100%;
        height: auto;
        margin: 0;
        bottom: 0;
      `}
    }
    .learn-more-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      width: 75%;
      max-width: 500px;
      box-sizing: border-box;

      ${responsive.TABLET`
                width: 100%;
                position: relative;
                left: -2rem;
        `}
      @media only screen and (max-width: 700px) {
        left: -5rem;
      }
      ${responsive.PHABLET`
                width: 100%;
                max-width: 500px;
                padding: 0 12px;
                left: 0;
                margin: 2rem auto;
        `}

        .learn-more-header {
        ${responsive.PHABLET`
                    margin: auto;
                    text-align: center;
            `}

        h5 {
          font-size: 28px;
          color: #fff2e6;
          ${responsive.PHABLET`
               font-size: 24px;
          `}
        }
      }
      .learn-more-body {
        width: 75%;
        ${responsive.TABLET`
                        width: 100%;
                    `}
        ${responsive.PHABLET`
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        `}
        h6 {
          margin: 1rem 0;
          font-size: 18px;
          color: #d4c4b4;
          ${responsive.TABLET`
                        font-size: 0.8rem;
                    `}
        }
        .learn-more-btn-container {
          .learn-more-btn {
            border: none;
            display: block;
            width: 167px;
            height: 41px;
            background: url("${LearnMoreBtnDefault}") no-repeat;
            color: #fff2e6;
            position: relative;
            transition: 200ms ease-in-out all;
            z-index: 2;
            span {
              font-size: 16px;
              font-weight: 400;
            }
            &:hover {
              background: url("${LearnMoreBtn}") no-repeat;
              transform: translateY(-3px);
              cursor: pointer;
            }
            ${responsive.PHABLET`
                margin: auto;
            `}
          }
        }
      }
    }
    .blur-bar {
      bottom: -10px;
      position: absolute;
      z-index: 1;
      width: 100%;
      ${responsive.TABLET`
                bottom: -21px;
                `}
    }
  }

  .third-banner-section {
    background-image: url("${ThirdBannerImage}");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    width: 100%;
    display: flex;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${responsive.PHABLET`
            background-attachment: fixed;
        `}
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 820px;
      margin: auto;
      box-sizing: border-box;
      ${responsive.TABLET`
          margin: 1.5rem auto 0;
      `}
      ${responsive.PHABLET`
          max-width: 500px;
          padding: 0 12px;
      `}
      .content-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #d4c4b4;
        img {
          margin: auto;
          margin-bottom: 0.8rem;
        }
        .about-title {
          margin-bottom: 1rem;
          font-size: 32px;
          ${responsive.PHABLET`
            font-size: 28px;
          `}
        }
        .about-text-container {
          width: 80%;
          .MuiTypography-subtitle2 {
            font-size: 16px;
          }
          text-align: center;
          ${responsive.PHABLET`
            width: 95%;
          `}
        }
      }
    }

    .images-container {
      display: flex;
      gap: 3rem;
      margin: 2rem auto;
      justify-content: space-between;
      ${responsive.TABLET`gap: 1rem;`}
      ${responsive.PHABLET`
            flex-direction: column;
        `}
        img {
        ${responsive.TABLET`width: 45%;`}
        ${responsive.PHABLET`
            width: 100%;

        `}
      }
    }
  }

  .fourth-banner-section {
    background-image: url("${FourthBannerImage}");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    height: 577px;
    ${responsive.TABLET`
        height: auto;
    `}
    ${responsive.PHABLET`
        flex-direction: column;
    `}
    .container {
      width: 50%;
      max-width: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${responsive.TABLET`
        width: 100%;
        margin: 1rem;
      `}
      ${responsive.PHABLET`
        margin: 1rem auto;
      `}
      .content-container {
        display: flex;
        flex-direction: column;
        color: #fff2e6;
        .content-header {
          h5 {
            font-size: 32px;
            @media only screen and (max-width: 599px) {
              font-size: 28px;
            }
          }
          .MuiTypography-subtitle2 {
            font-size: 16px;
            @media only screen and (max-width: 599px) {
              font-size: 14px;
            }
            @media only screen and (max-width: 325px) {
              font-size: 13px;
            }
          }
        }
        ${responsive.PHABLET`
            max-width: 500px;
            padding: 0 12px; 
            margin: 0;
        `}
        img {
          margin: 1rem auto;
        }
        > div,
        img {
          text-align: center;
        }
        h6 {
          margin-top: 28px;
          ${responsive.TABLET`
                          margin-top: 8px;
                            `}
          ${responsive.TABLET`
            margin-left: 5px;
            margin-right: 5px;
          `}
        }
      }
    }
    .fourth-banner-item {
      position: relative;
      z-index: 15;
      top: -40px;
      height: 616px;
      ${responsive.TABLET`
          width: 50%;
          height: auto;
          top: 0;
      `}
      ${responsive.PHABLET`
           width: 100%;
           margin: auto;
           max-width: 500px;
        `}
    }
  }

  .fifth-banner-section {
    background-image: url("${FifthBannerImage}");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner-item-1 {
      position: relative;
      top: -40px;
      z-index: 25;
      width: 60%;
      max-width: 680px;
      ${responsive.LAPTOP`
                margin-left: 0;
                margin-left: -5%;
            `};
      ${responsive.TABLET`
              margin-left: 0;
              margin-left: -5%;
              
              `};
      ${responsive.PHABLET`
              display: none
            `};
    }
    .content-container {
      ${responsive.PHABLET`
              display: none
            `};
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
      color: #fff2e6;
      max-width: 555px;
      width: 40%;
      .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        h5 {
          margin-top: 0.5rem;
        }
        h6 {
          margin-top: 11px;
          ${responsive.TABLET`
            margin-left: 0;
          `}
        }
      }
      .image-container {
        margin-top: 24px;
        img {
          width: 100%;
        }
      }
    }
    .fifth-banner-mobile-view {
      width: 100%;
      @media only screen and (min-width: 600px) {
        display: none;
      }
      .mobile-content-container {
        display: flex;
        flex-direction: column;
        margin: 1rem auto 2rem;
        color: #fff2e6;
        align-items: center;
        .header-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          overflow: hidden;
          img {
            margin: 1rem auto;
          }
          h5 {
            font-size: 28px;
          }
          h6 {
            margin-top: 8px;
          }
          ${responsive.PHABLET`
            max-width: 500px;
            padding: 0 12px;
          `};
        }
      }
      .mobile-banner-item-1 {
        width: 100%;
        max-width: 500px;
        position: relative;        
        margin-top: 1rem;
        box-sizing: border-box;
      }
      .mobile-banner-item-2 {
        width: 100%;
        max-width: 500px;
        margin: 1rem auto;
        padding: 0 1rem;
        box-sizing: border-box;
      }
    }
  }
`;

export default DashboardWrapper;
