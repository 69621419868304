import Api from "Helpers/ApiHandler";
import { PAGE_NOT_FOUND } from "Helpers/Paths";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NewsDetailsWrapper } from "./NewsDetails.style";
import NewsDetailsBody from "./NewsDetailsBody";

export default function NewsDetails() {
    const [newsArticleData, setNewsArticleData] = useState({});
    const language = useSelector(state => state.Auth.lang)
    const history = useHistory()

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
        getNewsArticleData()
        // eslint-disable-next-line
    }, [language]);

    const getNewsArticleData = async () => {
        let splitedLink = window.location.pathname.split('/')
        let id = splitedLink[splitedLink.length - 1]

        let response = await new Api().get(`games/news/${id}?field[]=images.img_microsite_background&field[]=author&field[]=updated&field[]=content&field[]=game_abbrs`, { skipAuth: true })

        if (!response.id || !(response?.game_abbrs && response.game_abbrs.includes("pwi"))) {
            history.replace(PAGE_NOT_FOUND)
        }
        setNewsArticleData(response);
    }

    return (
        <React.Fragment>
            <NewsDetailsWrapper >
                <div id="scroll-to-me" />
                <div className="bg-image" />
                <NewsDetailsBody newsArticleData={newsArticleData} getNewsArticleData={getNewsArticleData} />
            </NewsDetailsWrapper>
        </React.Fragment>
    )
}
