import React from 'react';
import { NewsPageWrapper } from './NewsPage.style';
import HeaderContent from './HeaderContent/HeaderContent';
import BodyContent from './BodyContent/BodyContent';

const NewsPage = (props) => {
    return (
        <NewsPageWrapper>
            <div id="scroll-to-me" />
            <HeaderContent />
            <BodyContent />
        </NewsPageWrapper>
    )
}

export default NewsPage;