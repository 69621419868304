import { createGlobalStyle } from "styled-components";
import { FONTS, responsive } from "./Constants";
import RobotoPrimary from "Assets/Fonts/Roboto-Regular.ttf";
import RobotoBold from "Assets/Fonts/Roboto-Bold.ttf";
import RobotoCondensed from "Assets/Fonts/RobotoCondensed-Regular.ttf";

export const UtilityStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400&display=swap');
    * {
        outline     : none;
    }
    html,
    body{
        background-color    : #000;
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
        margin              : 0;
        padding             : 0;
    }
    #root {
        font-family         : ${FONTS.PRIMARY};
    }
    #onetrust-consent-sdk {
        font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    :root {
        scrollbar-color: #d5d5d5 transparent;
        scrollbar-width: thin;
        --site-footer-background: #000;
        --footer-social-background: rgba(27, 27, 27, 0.87);
    }

    ::-webkit-scrollbar {
        z-index: 1000;
        height: 6px;
        width: 6px;
        background: transparent;
    }
    ::-webkit-scrollbar * {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        z-index: 1000;
        border-radius: 6px;
        width: 6px;
        background: #d5d5d5;
    }

    ::-webkit-scrollbar-thumb:hover {
        z-index: 1000;
        background: #aeaeae;
    }

    ::-webkit-scrollbar-track {
        z-index: 1000;
        width: 6px;
        background: transparent;
    }
    
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }

    .f-column {
        flex-direction: column;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }

    .hover-underline:hover {
        text-decoration: underline;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .MuiPaper-root.drop-down-paper {
        background-color: #8b1009;
        color: #eedcdb;
    }

    .MuiMenu-list.drop-down-list {
        padding: 0;
    }

    .MuiMenuItem-root.drop-down-item {
        border-bottom: 1px solid #c7a098;
    }
    
    .to-uppercase {
        text-transform: uppercase;
    }

    a#CybotCookiebotDialogPoweredbyCybot,
    div#CybotCookiebotDialogPoweredByText {
    display: none;
    }

    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
        display: none;
    }

    @font-face {
        font-family : ${FONTS.ROBOTO_REGULAR};
        src         : url(${RobotoPrimary});
    }
    @font-face {
        font-family : ${FONTS.ROBOTO_BOLD};
        src         : url(${RobotoBold});
    }
    @font-face {
        font-family : ${FONTS.ROBOTO_CONDENSED};
        src         : url(${RobotoCondensed});
    }
    .footer-section__link-full-wrap {
        width: 100%;
        text-align: center;
        white-space: normal;
    }    
`;
