import React, { useState, useEffect } from 'react';
import { Typography, Button, FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { BodyContentWrapper } from './BodyContent.style';
import HeaderIcon from 'Assets/Icons/HeaderIcon';
import NewsList from './NewsList';
import PwiButton from 'Components/Commons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Api from "Helpers/ApiHandler";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const NEWS_INCREMENT = 4;
const LIMIT = 20;

const hashToFilterMap = {
    "community": "pwi-community",
    "pwi-community": "pwi-community",
    "media": "pwi-media",
    "pwi-media": "pwi-media",
    "dev-blog": "dev-blog",
    "patch-notes": "patch-notes",
};

const BodyContent = () => {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [moreNews, setMoreNews] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [newsLength, setNewsLength] = useState(NEWS_INCREMENT);
    const [newsData, setNewsData] = useState([]);
    const language = useSelector(state => state.Auth.lang);
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        const handleScroll = event => {
            setFilterOpen(false);
        };
      
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({ ...getWindowDimensions() });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [language]);

    useEffect(() => {
        function handleHashChange() {
            const hash = window.location.hash.slice(1);
            let newFilter = "*";
            if(hash && Object.keys(hashToFilterMap).includes(hash)) {
                newFilter = hashToFilterMap[hash];
            }
            setSelectedCategory(newFilter);
        }
        window.addEventListener("hashchange", handleHashChange);
        handleHashChange();
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    useEffect(() => {
        if (newsData.length > 0) {
            setNewsData([]);
        }
        if (selectedCategory !== null) {
            setLoading(true);
            getNews().then((response) => {
                if (response?.news && Array.isArray(response.news)) {
                    setNewsData(response.news);
                    setMoreNews(response.more_news);
                    setNewsLength(NEWS_INCREMENT);
                } else {
                    setNewsData([]);
                    setMoreNews(false);
                }
            });
        }
        // eslint-disable-next-line
    }, [selectedCategory, language]);

    const getNews = async (offset = 0) => {
        setLoading(true);
        let response = await new Api().get("games/pwi/news", {
            params: {
                tag: selectedCategory === "all" ? "" : selectedCategory,
                limit: LIMIT,
                offset: offset,
                field: ["images.img_microsite_thumbnail", "updated"]
            },
            skipAuth: true,
        });

        setLoading(false);
        return response;
      };

    const isAllTag = (tagToTest) => {
        return ["", "all", "*"].includes(tagToTest?.toLowerCase()) ;
    }

    // Update hash to triger hash change function
    const handleFilterChange = (value) => {
        window.location.hash = `${isAllTag(value) ? "*" : value}`;
    }

    const handleFetchMore = () => {
        // Fetch more news data in the background when # of news currently shown is at least 10 away from what's stored in newsData
        if (newsLength > newsData.length - 10 && moreNews) {
            const offset = Math.ceil(newsLength / LIMIT) * LIMIT;
            getNews(offset).then((response) => {
                if (response?.news && Array.isArray(response.news)) {
                    setNewsData([...newsData, ...response.news]);
                    setMoreNews(response.more_news);
                }
            });
        }
    }

    const loadMoreHandler = () => {
        handleFetchMore();
        setNewsLength(nl => nl + NEWS_INCREMENT)
    }

    const handleFilterClose = () => {
        setFilterOpen(false);
    }
    const handleFilterOpen = () => {
        setFilterOpen(true);
    }
    

    return (
        <BodyContentWrapper>
            <div className="content-container flex">
                <div className="header-title ">
                    <HeaderIcon />
                    <Typography variant="h6" style={{ fontWeight: 700, fontSize: "18px", marginTop: 3 }} >{t("News.NEWS")}</Typography>
                </div>
                {windowDimensions.width > 745 ? (
                    <div className="header-filter">
                        <Button disableRipple onClick={() => handleFilterChange("*")} ><span className={isAllTag(selectedCategory) ? "btn-label active" : "btn-label"}>{t('News.ALL')}</span></Button> <strong>|</strong>
                        <Button disableRipple onClick={() => handleFilterChange("pwi-community")} ><span className={(selectedCategory === "pwi-community") ? "btn-label active" : "btn-label"}>{t("News.COMMUNITY")}</span></Button> <strong>|</strong>
                        <Button disableRipple onClick={() => handleFilterChange("pwi-media")} ><span className={(selectedCategory === "pwi-media") ? "btn-label active" : "btn-label"}>{t("News.MEDIA")}</span></Button> <strong>|</strong>
                        <Button disableRipple onClick={() => handleFilterChange("dev-blog")} ><span className={(selectedCategory === "dev-blog") ? "btn-label active" : "btn-label"}>{t("News.DEVBLOG")}</span></Button> <strong>|</strong>
                        <Button disableRipple onClick={() => handleFilterChange("patch-notes")}><span className={(selectedCategory === "patch-notes") ? "btn-label active" : "btn-label"}>{t("News.PATCHNOTES")}</span></Button>
                    </div>
                ) : (
                    <div className="header-filter-select">
                        <FormControl variant="outlined" fullWidth >
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedCategory}
                                onChange={(event) => handleFilterChange(event.target.value)}
                                open={filterOpen}
                                onClose={handleFilterClose}
                                onOpen={handleFilterOpen}
                                MenuProps={{
                                    classes: {
                                        paper: 'drop-down-paper',
                                        list: 'drop-down-list'
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                color="secondary"
                                className={"body-sub-header-select"}
                            >
                                <MenuItem className="drop-down-item to-uppercase" selected value={"*"}>{t('News.ALL')}</MenuItem>
                                <MenuItem className="drop-down-item to-uppercase" value={"pwi-community"}>{t("News.COMMUNITY")}</MenuItem>
                                <MenuItem className="drop-down-item to-uppercase" value={"pwi-media"}>{t("News.MEDIA")}</MenuItem>
                                <MenuItem className="drop-down-item to-uppercase" value={"dev-blog"}>{t("News.DEVBLOG")}</MenuItem>
                                <MenuItem className="drop-down-item to-uppercase" value={"patch-notes"}>{t("News.PATCHNOTES")}</MenuItem>

                            </Select>
                        </FormControl>
                    </div>

                )}

            </div>
            <div className="news-card-list" >
                {selectedCategory !== null && <NewsList
                    selectedCategory={selectedCategory}
                    newsData={newsData}
                    newsLength={newsLength}
                />}
            </div>
            <div className="load-more-container" >
                {
                    loading ? (
                        <CircularProgress color="secondary" />
                    ) : null
                }

                {
                    newsData.length && moreNews ? (
                        <PwiButton onClick={loadMoreHandler} style={{ marginTop: "1rem" }} defaultColor="black" color="#ac1818" >
                            <Typography variant="button">{t("News.LOADMORE")}</Typography>
                        </PwiButton>
                    ) : newsData.length === 0 && !loading ? t("news.no.news") : ""
                }

            </div>
        </BodyContentWrapper>
    );
};

export default BodyContent;