import { URL_CHARGE } from "Helpers/Paths";
import React, { useEffect, useState } from "react";
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import Instruction from "../Instruction";
import { HistoryWrapper } from "./History.style";
import { PaginationItem, Pagination } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Api from "Helpers/ApiHandler";
import useWindowSize from "Hooks/useWindowSize";
import { useTranslation } from "react-i18next";

function History(props) {
  const { campaignId } = props;
  const windowSize = useWindowSize();
  const history = useHistory();
  const language = useSelector((state) => state.Auth.lang);

  const [state, setState] = useState({
    currentPage: 1,
    perPage: 10,
  });
  const [totalHistory, setTotalHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchHistoryList();
    // eslint-disable-next-line
  }, [state.currentPage, campaignId]);

  const fetchHistoryList = async () => {
    try {
      if (campaignId) {
        setLoading(true);
        let response = await new Api().get(
          `chargerewards/pwi/history/${campaignId}?limit=${state.perPage}&offset=${(state.currentPage - 1)*state.perPage}`
        );
        if (Array.isArray(response) && response.length > 0) {
          setTotalCount(response[0]?.total_rows || 0);

          setTotalHistory(response.slice(1));

        }
        setLoading(false);
      }
    } catch (e) {
      console.log("history error: ", e)
    }
  };
  const HEADER = [
    { title: t("Charge.Action"), width: "12%", id: 1 },
    { title: t("Charge.Reward"), width: "14%", id: 2 },
    { title: t("Charge.Quantity"), width: "10%", id: 3 },
    { title: t("Charge.Points"), width: "12%", id: 4 },
    { title: t("Charge.RPBalance"), width: "12%", id: 5 },
    { title: t("Charge.Character"), width: "12%", id: 6 },
    { title: t("Charge.Server"), width: "13%", id: 7 },
    { title: t("Charge.TimePacific"), width: "15%", id: 8 },
  ];
  return (
    <HistoryWrapper>
      <div className="history-header">
        <div className="redemption-history-title">{t("Charge.RPREDEMPTIONHISTORY")}</div>
        <div
          className="view-rewards"
          onClick={() => {
            history.push("/" + language + URL_CHARGE);
          }}
        >
          {t("Charge.ViewRewards")}
        </div>
      </div>

      {windowSize.width >= BREAKPOINTS_VALUE.TABLET && (
        <React.Fragment>
          <div className="table-header">
            {HEADER.map((item, index) => (
              <div
                className="item"
                style={{ width: `${item.width}` }}
                key={item.id}
              >
                {item.title}
              </div>
            ))}
          </div>

          {totalHistory.length && totalHistory.length > 0 ? (
            <div className="table-body">
              {totalHistory.map((item, index) => (
                <div
                  className={`body-item ${index / 2 === 0 ? "even" : "odd"}`}
                  key={index}
                >
                  <div className="item action">{t(item.action)}</div>
                  <div className="item reward">{item?.items?.name}</div>
                  <div className="item quantity">{item?.items?.quantity}</div>
                  <div className={`item points ${item.reward_points < 0 && "minus"}`}>
                    <b>{item.reward_points}</b>
                  </div>
                  <div className="item rp_balance">{item.balance}</div>
                  <div className="item character">{item?.roles?.name}</div>
                  <div className="item server">{item?.roles?.server?.name}</div>
                  <div className="item time">{item.transaction_date}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="table-body no-content">
              {
                !loading ?
                  (<Typography>{t("Charge.NoHistory")}</Typography>) :
                  (<Typography>Loading....</Typography>)
              }
            </div>
          )}
        </React.Fragment>
      )}

      {totalHistory.length && totalHistory.length > 0 ? (
        windowSize.width < BREAKPOINTS_VALUE.TABLET ? (
          <React.Fragment>
            {totalHistory.map((item, index) => (
              <div className="list">
                <div className="list-item">
                  <div className="key">{t("Charge.Action")}</div>
                  <div className="value">{t(item.action)}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.Reward")}</div>
                  <div className="value reward">{item?.items?.name || ""}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.Quantity")}</div>
                  <div className="value">{item?.items?.quantity}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.Points")}</div>
                  <div className={`value points ${item.reward_points < 0 && "minus"}`}>
                    <b>{item.reward_points}</b>
                  </div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.RPBalance")}</div>
                  <div className="value">{item.balance}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.Character")}</div>
                  <div className="value">{item?.roles?.name || ""}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.Server")}</div>
                  <div className="value">{item?.roles?.server?.name || ""}</div>
                </div>
                <div className="list-item">
                  <div className="key">{t("Charge.TimePacific")}</div>
                  <div className="value">{item.transaction_date || ""}</div>
                </div>
              </div>
            ))}
          </React.Fragment>
        ) : null
      ) : (
        windowSize.width < BREAKPOINTS_VALUE.TABLET && (
          <div className="table-body no-content">
            {
              !loading ?
                (<Typography>{t("Charge.NoHistory")}</Typography>) :
                (<Typography>Loading....</Typography>)
            }
          </div>
        )
      )}
      {/* <div className="pagination">
                <div className="page">Page 1 of 1</div>
                <div className="prev" onClick={onPrev}></div>
                <div className="next" onClick={onNext}></div>
            </div> */}
      {totalCount && totalCount > 0 ? (
        <div className="pagination-container">
          <Pagination
            renderItem={(renProps) => {
              if (renProps.type === "first") {
                return (
                  <Button className="pagination-btn" {...renProps}>
                    <span className="double-arrow flip"></span>
                  </Button>
                );
              } else if (renProps.type === "last") {
                return (
                  <Button className="pagination-btn" {...renProps}>
                    <span className="double-arrow"></span>
                  </Button>
                );
              } else if (renProps.type === "next") {
                return (
                  <Button className="pagination-btn" {...renProps}>
                    <span className="single-arrow"></span>
                  </Button>
                );
              } else if (renProps.type === "previous") {
                return (
                  <Button className="pagination-btn" {...renProps}>
                    <span className="single-arrow flip"></span>
                  </Button>
                );
              }
              return <PaginationItem {...renProps} />;
            }}
            count={Math.ceil(totalCount / 10)}
            showFirstButton
            showLastButton
            onChange={async (ch, pageNuber) => {
              setState({ perPage: 10, currentPage: pageNuber });
            }}
          ></Pagination>
        </div>
      ) : null}
      <Instruction />
    </HistoryWrapper>
  );
}

export default History;

//eslint-disable-next-line
const VALUES = [
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: -6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: -6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: 6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: 3195,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: 500,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: -6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: 4000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: 2000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: -6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
  {
    action: "Spend",
    reward: "Grand Lv5 Rune Pack",
    quantity: "1",
    points: -6000,
    rp_balance: 20565,
    character: "TestOnee",
    server: "Eydra",
    time: "8/4/2021 6:10 PM",
  },
];

//eslint-disable-next-line
const DUMMY_VALUES = [
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 10:42:41",
    reward_points: "20000",
    balance: "20000",
    action: "Grant Points",
    items: [],
    roles: [],
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 10:42:41",
    reward_points: "20000",
    balance: "20000",
    action: "Grant Points",
    items: [],
    roles: [],
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
  {
    transaction_date: "2021-10-27 11:04:35",
    reward_points: "-240",
    balance: "19760",
    action: "Spend Points",
    items: { id: "23513", name: "Fusion Agent Lv3" },
    roles: {
      id: "5825282058",
      name: "VBNZXC",
      server: { id: "42", name: "Lionheart" },
      game: { id: "fw", name: "Forsaken World" },
    },
  },
];
