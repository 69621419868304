import styled from "styled-components";
import ButtonIconDefault from 'Assets/Component 28 – 1 default.svg';
import ButtonIcon from 'Assets/Component 28 – 1.svg';
import DisabledBtnIcon from 'Assets/disabled-btn-icon.svg';

export const ButtonWrapper = styled.span.attrs({
    className: 'ButtonWrapper',
})`
    border: none;
    display: block;
    
    button:disabled {
        cursor: not-allowed !important;
    }
    .default-class {
        width: 167px;
        height: 41px;
        background-image: url("${ButtonIconDefault}");
        background-color: transparent;
        transition: 200ms ease-in-out all;
        position: relative;
        border: none;
        color: #FFF2E6;
        font-size: 16px;
        cursor: pointer;
        &:hover:not(:disabled) {
            background-image: url("${ButtonIcon}");
            transform: translateY(-3px);
        }
        &:disabled {
            background-image: url("${DisabledBtnIcon}");
        }
        // Preload images and prevent the 'flash' on first hover
        &:after {
            position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
            content: url("${ButtonIcon}"); // load images
        }
    }
`