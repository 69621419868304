import { useState, useEffect } from "react";
import { debounce } from "Helpers/Utils";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });
  
  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowSize({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)   
    }
  }, []);

  return windowSize;
}

export default useWindowSize;