// CORE
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addLang } from 'Redux/Auth/Actions';

import { withRouter } from 'react-router';

import Toast from 'Components/Commons/Toast/Toast'

import { hideToast } from "Redux/General/Actions";
import i18n from 'i18';
import { store } from 'Redux/Store';

class EventManager extends Component {

    componentDidMount() {
        let path = this.props.history.location.pathname.split("/")[1];
        this.changeLanguage(path);
    }

    changeLanguage = (value) => {
        const langs = ['en', 'fr', 'de', 'it', 'ru', "es"];
        let find = langs.find(item => item === value)
        if (find) {
            i18n.changeLanguage(value);
            store.dispatch(addLang({ lang: value }));
            document.documentElement.lang = value;
            return;
        }
        if(!""
           && localStorage.getItem('lang')
           && langs.includes(localStorage.getItem('lang'))
           ) {
            i18n.changeLanguage(localStorage.getItem('lang'));
            store.dispatch(addLang({ lang: localStorage.getItem('lang') }));
            document.documentElement.lang = localStorage.getItem('lang');
            return; 

        }

        i18n.changeLanguage("en")
        document.documentElement.lang = "en";
        store.dispatch(addLang({ lang: "en" }))
    }
    
	render() {
		return (
			<React.Fragment>
                <Toast 
                    open        = {this.props.toast.enable}
                    message     = {this.props.toast.message}
                    toastType   = {this.props.toast.toastType}
                    handleClose = {this.props.hideToast}
                />
			</React.Fragment>
		);
    }
}

const mapReduxStateToProps = state => {
    return {
        toast   : state.General.toast,
    }
}

export default withRouter(connect(mapReduxStateToProps, {
    hideToast
})(EventManager));
