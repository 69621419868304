
import React, { useEffect, useRef, useState }  from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { generateCodeVerifier, generateCodeChallenge } from 'Helpers/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { codeVerifier, logOutUser, loginUser, updateProfileInfo } from "Redux/Auth/Actions";
import { showToast, ToastType } from 'Redux/General/Actions';
import querystring from 'querystring';
import HeaderLogo from 'Assets/Logos/header_logo.png'
import { useTranslation } from 'react-i18next';
import Api from 'Helpers/ApiHandler';

import {
    HeaderWrapper,
    GameDropdown,
    Branding,
    HamburgerWrapper,
    UserComponents,
    CtaComponents,
    LinkComponents
} from "@fe-packages/components-and-utilities";

function Topbar() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [cartCount, setCartCount] = useState(0);
    const containerRef = useRef(document.getElementById('everything-container'));
    const {
        REACT_APP_OAUTH_URL,
        REACT_APP_API_URL,
        REACT_APP_ACCOUNT_URL,
        REACT_APP_SITE_GAME_ABBR,
        REACT_APP_SITE_URL,
        REACT_APP_CLIENT_ID,
        REACT_APP_DOWNLOAD_URL,
        REACT_APP_BILLING_URL,
      } = process.env;

    const code_verifier = useSelector(state => state.Auth.codeVerifier);
    const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
    const language = useSelector(state => state.Auth.lang);
    const userInfo = useSelector(state => state.Auth.profileInfo);

    useEffect(() => {
        let urlProps = new URLSearchParams(location?.search)

        if (urlProps.get('code')) {
            let auth_token = urlProps.get('code');

            let requestData = {
                grant_type: "authorization_code",
                client_id: REACT_APP_CLIENT_ID,
                code_verifier,
                code: auth_token,
                redirect_uri: `${window.location.origin}${window.location.pathname}`,
            };

            getToken(requestData);
        }
        getCartCount();
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        const eventSignIn = (e) => {
            signIn(e?.detail?.redirect);
        }
        const eventLogin = (e) => {
            loginUserAndGetInfo({
                token: e?.detail?.token,
            })
        }

        window.addEventListener("PWI::sign_in", eventSignIn, false);
        window.addEventListener("PWI::get_user_details", eventLogin, false)
        return (() => {
            window.removeEventListener("PWI::sign_in", eventSignIn);
            window.removeEventListener("PWI::get_user_details", eventLogin);
        });
        // eslint-disable-next-line
    }, []);

    const getToken = async (requestData) => {

        fetch(`${REACT_APP_OAUTH_URL}/token`, {
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: querystring.stringify(requestData),
        }).then(response => {
            return response.json()
        }).then(data => {
            loginUserAndGetInfo({token: data.token_type + " " + data.access_token});
        }).catch((e) => {
            console.log("error in token", e);
        })
    }

    const getCartCount = async () => {
        let response = await new Api().get("games/cart");
        setCartCount(response?.cart_count || 0)
    }


    const loginUserAndGetInfo = async ({ token }) => {
        dispatch(loginUser({ accessToken: token }))
        getUserDetails();
    };

    const getUserDetails = async () => {
        let response = await new Api().get("users/me");
        dispatch(updateProfileInfo(response));
        
        let redirect = sessionStorage.getItem("PWI::login_redirect");
        sessionStorage.removeItem("PWI::login_redirect");
        history.replace(redirect !== null ? redirect : `${window.location.pathname}`);
        getCartCount();
    }

    const playNowHandler = () => {
        window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=pwi`);
        history.push(`/${language}/success`)
    }

    const signIn = (redirect) => {
        sessionStorage.setItem("PWI::login_redirect", typeof redirect === "string" ? redirect : window.location.pathname);
        const code = generateCodeVerifier(128);
        dispatch(codeVerifier({ codeVerifier: code }));
        const codeChallenge = generateCodeChallenge(code);
        window.open(`${REACT_APP_OAUTH_URL}/authorize?theme=playpwi&game_abbr=pwi&referred_url=${REACT_APP_SITE_URL}/&state=629dd585fafa8375498851a0c67a8a6e&scope=id%20email&response_type=code&approval_prompt=auto&redirect_uri=${`${REACT_APP_SITE_URL}/${language}/`}&client_id=${REACT_APP_CLIENT_ID}&lang=${language}&code_challenge=${codeChallenge}&code_challenge_method=S256`, '_self')
    }

    const logOut = async () => {
        dispatch(logOutUser());
        history.replace(window.location.pathname);
    }

    const chargeClickHandler = (e) => {
        if (!isLoggedIn) {
            dispatch(showToast(t('toast.signin.first'), ToastType.ERROR));
            e.preventDefault();
            return;
        }
        history.push("/" + language + "/charge");
        e.preventDefault();
    }

    return 	<HeaderWrapper lang={language} apiBaseUrl={REACT_APP_API_URL} arcGamesBaseUrl={REACT_APP_ACCOUNT_URL} containerRef={containerRef}>
		<GameDropdown micrositeGame={REACT_APP_SITE_GAME_ABBR} />
		<Branding gameName="Perfect World International" brand={<img src={HeaderLogo} width="22" height="24" alt="PWI" />}/>
		<HamburgerWrapper>
			<LinkComponents.LinksWrapper>
                <LinkComponents.MultiLink
                    keyIdentfier="expansions-links"
                    links={[
                        { item: <LinkComponents.MultiLinkItem href={`${REACT_APP_DOWNLOAD_URL}/${language}/store/pwi/list`} target="_blank">{t("Shop.Store")}</LinkComponents.MultiLinkItem> },
                        { item: <LinkComponents.MultiLinkItem href={`/${language}/charge`} onClick={chargeClickHandler}>{t("TopBar.chargepromo")}</LinkComponents.MultiLinkItem> }
                    ]}
                >
                    {t("TopBar.Game")}
                </LinkComponents.MultiLink>
				<LinkComponents.Link href={`/${language}/news`} onClick={(e) => { history.push(`/${language}/news`); e.preventDefault()}}>{t("TopBar.News")}</LinkComponents.Link>
				<LinkComponents.Link href={`https://support.arcgames.com/hc/${language === "en" ? "en-us" : language}/categories/360001419193-PWI`} target="_blank" rel="noreferrer noopenner">{t("TopBar.Support")}</LinkComponents.Link>
				<LinkComponents.Link href={`${REACT_APP_ACCOUNT_URL}/${language}/forums/pwi`} target="_blank" rel="noreferrer noopenner">{t("TopBar.FORUM")}</LinkComponents.Link>
			</LinkComponents.LinksWrapper>
			<UserComponents.UserWrapper paymentBaseUrl={REACT_APP_BILLING_URL}>
                <UserComponents.User
                    userNickname={isLoggedIn ? userInfo.nickname : ""}
                    signInAction={{type: "function", action: signIn}}
                    signOutAction={{type: "function", action: logOut}}
                />
                <UserComponents.Cart cart_count={cartCount} />
				<UserComponents.LangSelector langList={["en", "de", "fr"]} />
			</UserComponents.UserWrapper>
			<CtaComponents.CtaWrapper 
				primary={<CtaComponents.PrimaryButton
					as="button"
					onClick={playNowHandler}
				>{t("Home.PLAYNOW")}</CtaComponents.PrimaryButton>}
			/>
		</HamburgerWrapper>
	</HeaderWrapper>;
}

export default Topbar;
