// CORE
import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';

// USER DEFINED
import Api from 'Helpers/ApiHandler';
import Routes from "Routes/Route";
import TopBar from "./Commons/Topbar/Topbar";
import Footer from "./Commons/Footer/Footer";
import EventManager from "./EventManager/EventManager";
import { setUserIp, setIsGdpr, setIsInternal, setRegionOverwrite } from "Redux/Auth/Actions";
import { store } from 'Redux/Store';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'

const Website = (props) => {
	const { search } = useLocation();
	const [showContent, setShowContent] = useState(false);
	const lang = useSelector(state => state.Auth.lang);

	useEffect(() => {
		const urlProps = new URLSearchParams(search);
		const setUserNetworkData = async () => {
			let { long_ip, internal, gdpr} = await new Api().get(`regions?field[]=internal&field[]=gdpr`, { skipAuth: true });
		
			store.dispatch(setIsInternal({ isInternal: internal }));
		
			if (internal && urlProps.get('cc')) {
			  let code = urlProps.get('cc');
			  store.dispatch(setRegionOverwrite({ regionOverwrite: code }));
			  
			  let overwriteData = await new Api().get(`regions/${code}?field[]=internal&field[]=gdpr`, { skipAuth: true });
		
			  long_ip = overwriteData.long_ip;
			  gdpr = overwriteData.gdpr;
			}
		
			store.dispatch(setIsGdpr({ isGdpr: gdpr }));
			store.dispatch(setUserIp({ userIP: long_ip }));
		}
		setUserNetworkData();

		// If both of these are true, it means we are in the midst of logging user in on oauth
		//   In that case, we don't show content yet until logging is compolete so that we don't flash the home page
		setShowContent(!(urlProps.get('code') && sessionStorage.getItem("PWI::login_redirect")));
	}, [search]);

	document.getElementsByTagName('html')[0].lang = lang;
	
	return (
		<React.Fragment>
			<TopBar />
			{showContent && 
				<div id="everything-container">
					<Routes lang={lang} />
					<Footer />
				</div>
			}
			<EventManager />
		</React.Fragment>
	);
}

// const mapReduxStateToProps = (state) => ({
// 	isLoggedIn: state.Auth.isLoggedIn,
// })

// export default connect(mapReduxStateToProps)(Website);

export default Website;