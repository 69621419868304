import React from "react"
import { PageNotFoundContainer } from "./PageNotFound.style";
// import { ContainerWrapper } from "Components/Website.style";
// import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Button from 'Components/Commons/Button/Button';
import { useTranslation } from "react-i18next";

const PageNotFound = (props) => {
    const history = useHistory();
    const { t: translate } = useTranslation();

    return (
        <PageNotFoundContainer>
            <div id="scroll-to-me" />
            {/* <ContainerWrapper> */}
            <div className="container">

                <div className="page-404">
                    <div className="page-404">
                        <div className="page-404-font"><b>4</b></div>
                        <div className="page-404-font"><b>0</b></div>
                        <div className="page-404-font"><b>4</b></div>
                    </div>

                </div>

                <div>
                    <h1>{translate("page.not.found.title")}</h1>
                </div>

                <div className="description">
                    <p className="description-font">{translate("page.not.found.description")}</p>
                </div>

                <div className="return-homepage">
                    <span onClick={() => history.replace("/")} className="return-homepage-font">{translate("return.to.homepage")}</span>
                    {/* <Button onClick={() => history.replace("/")} className="return-homepage">Return to homepage</Button> */}
                </div>

            </div>
            {/* </ContainerWrapper> */}
        </PageNotFoundContainer>
    )
}

export default PageNotFound