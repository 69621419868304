import React, { useEffect, useState } from 'react';
import { HeaderContentWrapper } from './HeaderContent.style';
import CarousalContent from './CarousalContent/Carousal';
import { COLORS } from 'Styles/Constants';

import Carousel from "react-material-ui-carousel";
import Api from 'Helpers/ApiHandler';
import { useParams } from 'react-router-dom';
import { BREAKPOINTS_VALUE } from "Styles/Constants";


const HeaderContent = (props) => {

    const [featuredNews, setFeaturedNews] = useState([]);
    const { lang } = useParams();
    let darkUserAgent = navigator?.userAgent.includes('PWECoreClient') || navigator?.userAgent.includes('PWECoreOverlay')

    useEffect(() => {
        const getFeaturedNews = async () => {
            let response = await new Api().get("games/pwi/newsbanners", {
                params: {
                    type: "news_featured",
                    field: ["media"]
                },
                skipAuth: true,
            });
            if (Array.isArray(response)) {
                setFeaturedNews(response);
            }
        }
        getFeaturedNews();
    }, [lang])

    const carousalStyle = () => {
        let style = { marginTop: !darkUserAgent && '50px', textAlign: 'center' };

        if (darkUserAgent && window.innerWidth <= BREAKPOINTS_VALUE.MOBILE) {
            style['position'] = 'absolute';
            style['top'] = "27%";
        } else if (darkUserAgent && window.innerWidth <= BREAKPOINTS_VALUE.TABLET) {
            style['position'] = 'absolute';
            style['top'] = "48%";
            style['paddingRight'] = '20px'
        }

        return style
    }

    return (
        <HeaderContentWrapper darkUserAgent={darkUserAgent} >
            <section className="header-container f-v-center f-h-center" >
                <div className="carousal-container-header-content f-v-center f-h-center flex">
                    <Carousel showThumbs={false}
                        navButtonsProps={{
                            style: {
                                display: "none"
                            }
                        }}
                        IndicatorIcon={<div />}

                        indicatorIconButtonProps={{
                            style: {
                                width: "62px",
                                height: "8px",
                                background: "white",
                                marginLeft: '10px',    // 1
                                color: 'blue',       // 3
                                borderRadius: "10px",
                            }
                        }}

                        activeIndicatorIconButtonProps={{
                            style: {
                                background: `${COLORS.SECONDARY}`,
                            }
                        }}
                        indicatorContainerProps={{
                            style: carousalStyle()
                        }}
                        autoPlay={true}
                        stopAutoPlayOnHover={true}
                        interval={4000}
                    >
                        {
                            featuredNews.map(item => {
                                return (<CarousalContent key={item.id} item={item} />)
                            })
                        }
                        {/* <CarousalContent />
                    <CarousalContent />
                    <CarousalContent /> */}
                    </Carousel>
                </div>
            </section>
        </HeaderContentWrapper>
    );
};

export default HeaderContent;