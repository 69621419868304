import React from "react";
import { RelatedNewsWrapper } from "./RelatedNews.Style.js";
import FallbackImage from "Assets/News/news-fallback.webp";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";

export default function RelatedNews(props) {
    const { newsItem, getNewsArticleData } = props
    const language = useSelector(state => state.Auth.lang);
    const history = useHistory();

    const getArticle = () => {
        history.replace({ pathname: "/" + language + "/news-details/" + newsItem.id, state: { ...newsItem } });
        getNewsArticleData();
    }

    const scrollToTop = () => {
        var elmnt = document.getElementById("scroll-to-me");
        elmnt && elmnt.scrollIntoView({ behavior: "smooth" });
    };


    return (
        <RelatedNewsWrapper onClick={() => { getArticle(); scrollToTop() }} className="flex f-v-center" style={{ cursor: "pointer" }}>
            <div className="image-section">
                <div className="border-container">
                    <div className="contained-image" style={{ backgroundImage: `url(${newsItem?.images?.img_microsite_thumbnail?.url ?? FallbackImage})` }} />
                </div>
            </div>

            <div className="text-section">
                <div className="related-news-title ">
                    {newsItem?.title}
                </div>
                <div className="related-news-text">
                    {newsItem?.summary}
                </div>
            </div>
        </RelatedNewsWrapper>
    )
}
