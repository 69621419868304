export const ACTION_TYPES = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    UPDATE_TOTAL_SCORE: "UPDATE_TOTAL_SCORE",
    ADD_LANG: "ADD_LANG",
    UPDATE_LANG: "UPDATE_LANG",
    CODE_VERIFIER: "CODE_VERIFIER",
    USER_IP: "USER_IP",
    SET_IS_GDPR: "SET_IS_GDPR",
    SET_IS_INTERNAL: "SET_IS_INTERNAL",
    SET_REGION_OVERWRITE: "SET_REGION_OVERWRITE",
}

export const loginUser = (data) => {
    // SET YOUR LOGIN INFO HERE
    localStorage.setItem('isLoggedIn', true)
    localStorage.setItem('accessToken', data.accessToken)
    return {
        type : ACTION_TYPES.LOGIN_USER,
        ...data
    }
}

export const logOutUser = () => {
    localStorage.clear();
    return {
        type : ACTION_TYPES.LOGOUT_USER,
    }
}

export const addLang = (data) => {
    localStorage.setItem('lang', data.lang)
    return {
        type: ACTION_TYPES.ADD_LANG,
        ...data
    }
}

export const updateLang = (data) => {
    localStorage.setItem('lang', data.lang)
    return {
        type: ACTION_TYPES.UPDATE_LANG,
        ...data
    }
}

export const codeVerifier = (data) => {
    localStorage.setItem('codeVerifier', data.codeVerifier)
    return {
        type: ACTION_TYPES.CODE_VERIFIER,
        ...data
    }
}

export const updateProfileInfo = (data) => {
    localStorage.setItem('profileInfo', JSON.stringify(data))
    return {
        type: ACTION_TYPES.UPDATE_PROFILE,
        profileInfo: data
    }
}

export const setUserIp = ({userIP}) => {
    return {
        type: ACTION_TYPES.USER_IP,
        userIP: userIP,
    };
};
  

export const setIsGdpr = ({isGdpr}) => {
    localStorage.setItem("isGdpr", Boolean(isGdpr).toString());
    return {
        type: ACTION_TYPES.SET_IS_GDPR,
        isGdpr: isGdpr,
    };
};
  
export const setIsInternal = ({isInternal}) => {
    localStorage.setItem("isInternal", Boolean(isInternal).toString());
    return {
        type: ACTION_TYPES.SET_IS_INTERNAL,
        isInternal: isInternal,
    };
};
  
export const setRegionOverwrite = ({regionOverwrite}) => {
    return {
        type: ACTION_TYPES.SET_REGION_OVERWRITE,
        regionOverwrite: regionOverwrite,
    };
};
