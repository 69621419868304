import styled from "styled-components";
import { COLORS, responsive } from "Styles/Constants";

export const NewsCardWrapper = styled.div.attrs({
  className: 'NewsCardWrapper',
})`
  background-color: ${(props) => (props.darkTheme ? "#0d0d0d" : "none")};
  border-radius: 0 20px 20px 0;
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  margin: 2.5rem 0;
  cursor: pointer;

  &:hover {
    .news-content-container {
      .news-text {
        color: ${COLORS.SECONDARY};
      }
  
      .news-sub-text {
        text-decoration-color: #000;
      }
    }
  }

  ${responsive.PHABLET`
    flex-direction: column;
    max-width: 450px;
  `}
  .image-container {
    display: flex;
    margin: 7px;
    width: 50%;
    max-width: 450px;

    ${responsive.PHABLET`
      width: 100%;
      max-width: none;
    `}

    .container {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      aspect-ratio: 16 / 9;
      height: auto;
    }

    .border-container {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: calc(100% + 16px);
      height: calc(100% + 14px);
      top: -7px;
      left: -9px;

      ${responsive.TABLET`
        width: calc(100% + 16px);
        height: calc(100% + 14px);
        top: -7px;
        left: -9px;
      `}

      ${responsive.PHABLET`
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
    `}
    }
  }
  .news-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 50%;
    ${responsive.PHABLET`
      width: 100%;
      max-width: none;
      padding: 1rem;
    `}
    .News-header-txt {
      color: #b29271;
      text-transform: uppercase;
      font-size: 22px;
    }

    .news-text {
      text-transform: uppercase;
      font-size: 24px;
      color: ${(props) => (props.darkTheme ? "#fff" : "black")};
      transition: all 0.2s;
      margin-block-end: 0.5em;
    }

    .news-date {
      font-size: 12px;
      color: #000;
      opacity: 0.7;
      margin: 0;
    }

    .news-sub-text {
      margin-block-start: 0.5em;
      font-size: 20px;
      color: ${(props) => (props.darkTheme ? "#fff" : "#6a574c")};
      width: 100%;
      line-height: 1;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: all 0.2s;
    }
  }
`;
