import styled from "styled-components";
import NewHeaderImage from 'Assets/newsheader.png'
import { COLORS, responsive } from "Styles/Constants";

export const HeaderContentWrapper =  styled.div.attrs({
    className: 'HeaderContentWrapper',
  })`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    .header-container {
        width: 100%;
        min-height: 535px;
        background-image: ${(props) => props.darkUserAgent ? "none" : `url("${NewHeaderImage}")`};
        background-color: ${(props) => props.darkUserAgent && "transparent"};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        .carousal-container-header-content {
            width: 90%;
            max-width: 1200px;

            & > div {
                width: 100%;
            }

            ${responsive.TABLET`
                margin-bottom: 1rem;
            `}

            .MuiIconButton-sizeSmall {
                @media (hover: hover) {
                    &:hover {
                        background-color: ${COLORS.SECONDARY} !important;
                    }
                }
            }
        }
    }
`;