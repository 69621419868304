import React from "react";
import { useHistory } from "react-router-dom";
import FallbackImage from "Assets/News/news-fallback.webp";
import { URL_NEWS_DETAILS } from 'Helpers/Paths';
import { useSelector } from "react-redux";
import { Typography } from '@material-ui/core';
import FrameLarge from "Assets/News/FrameLarge.png";
import { NewsCardWrapper } from './NewsCard/NewsCard.style';
import { useTranslation } from 'react-i18next'

function NewsList({newsData, newsLength, selectedCategory}) {
  const { t } = useTranslation();
  const history = useHistory();

  const language = useSelector(state => state.Auth.lang);

  const handleNewsContentClick = (value) => {
    history.push({ pathname: "/" + language + URL_NEWS_DETAILS + '/' + value.id, state: { ...value } })
  };

  const getDateText = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString(language)} ${date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" })}`;
}

  const getPlatformTag = () => {

    if (!newsData?.tags?.length) return null;

    const tagData = {
        "pwi-community": t("News.COMMUNITY"),
        "pwi-media": t("News.MEDIA"),
        "dev-blog": t("News.DEVBLOG"),
        "patch-notes": t("News.PATCHNOTES"),
    }

    if (Object.keys(tagData).includes(selectedCategory)) {
        return tagData[selectedCategory];
    } else {
        for (let tag of newsData.tags) {
            if (tagData.hasOwnProperty(tag)) {
                return tagData[tag];
            }
        }
    }

    return null;
}

  return (
    <React.Fragment>
      {newsData.slice(0, newsLength).map((news) => 
          <NewsCardWrapper key={news.id} onClick={() => handleNewsContentClick(news)}>
            <div className="image-container">
                <div className='container' style={{ backgroundImage: `url(${news?.images?.img_microsite_thumbnail?.url ?? FallbackImage})` }}>
                    <div className='border-container' style={{ backgroundImage: `url(${FrameLarge})` }}></div>
                </div>
            </div>

            <div className="news-content-container">
                {getPlatformTag() !== null && <Typography variant="h6" className="News-header-txt">{getPlatformTag()}</Typography>}
                <Typography variant="h5" className="news-text">{news.title}</Typography>
                <Typography variant="p" className="news-date">{getDateText(news?.updated)}</Typography>
                <Typography variant="subtitle2" className="news-sub-text" >{news.summary}</Typography>
            </div>
        </NewsCardWrapper>
      )}
    </React.Fragment>
  );
}

export default NewsList;
