import styled, { keyframes } from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const shineAnimation = keyframes`
    0% {
        background-position: right;    
    }
`

export const RewardsWrapper = styled.div.attrs({
    className: 'RewardsWrapper',
  })`
    display: flex;
    flex-wrap: wrap;

    ${responsive.PHABLET`
        width: 100%;
        justify-content: center;
    `}
    .reward-list-card {
        width: 50%;
        border-bottom: 2px solid #b29271;
        border-right: 2px solid #b29271;
        display: flex;
        padding: 1.5rem;
        margin-top: 2px;
        box-sizing: border-box;
        @media only screen and (max-width: 650px) {
            padding-left: 0.5rem;
            justify-content: center;
        }
        ${responsive.PHABLET`
            width : 100%;
            border-bottom: 2px solid #b29271
            margin-top: 16px;
            padding-left: 0;
        `}
        .image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
        }

        .image {
            width: 52px;
            height: 52px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .limited-badge {
            font-size: 11px;
            font-weight: lighter;
            padding: 3px;
            margin-top: 4px;
            border-radius: 2px;
            color: #FFF;
            width: fit-content;
            text-transform: uppercase;
            background: linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%) ${COLORS.SECONDARY_LIGHT};
            background-size: 300% 100%;
            animation: ${shineAnimation} 2s infinite;
        }

        .reward-container {
            width: calc(100% - 110px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ${responsive.TABLET`
                width: calc(100% - 80px);
            `}

            .title {
                font-family: ${FONTS.PRIMARY_BOLD};
                font-size: 16px;

                ${responsive.TABLET`
                    font-size : 14px;
                `}
            }
            .key {
                color : #614F4F;
                margin : 5px 0;
                font-family: ${FONTS.SECONDARY};
                
                ${responsive.TABLET`
                    font-size : 14px;
                `}
                .quantity-number {
                    color : #614F4F;
                    font-family: ${FONTS.SECONDARY_BOLD};
                }
            }
            .description {
                color : #614F4F;
                margin: 5px 0;
                font-family: ${FONTS.SECONDARY};
                font-size: 16px;
                min-height: 40px;
                line-height: 20px;

                p {
                    margin: 0 0 10px;
                }

                img {
                    width: 100% !important;
                    height: auto !important;
                }

                ${responsive.TABLET`
                    font-size : 14px;
                `}
            }

            .claim {
                color : #fff;
                font-size: 14px;
                font-family: ${FONTS.SECONDARY};
                background-color: ${COLORS.SECONDARY};
                width : 130px;
                height: 40px;
                border-radius: 4px;
                margin: 20px 0;

                ${responsive.TABLET`
                    width : 100px;
                    height : 35px;
                `}
                &:hover {
                    background-color : #DE733F;
                }
                &.disabled {
                    color : #C5BCAE;
                }
            }
        }
        .tooltip {
            display: inline-block;
            position: relative;
            text-align: left;
            margin-top: 12px;

            &:hover {
                .tooltip-box {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .tooltip-box {
                width: max-content;
                top: 40px;
                left: 50%;
                transform: translate(-50%, 0);
                padding: 8px 16px;
                color: #6F7070;
                background-color: #FFFFFF;
                font-weight: normal;
                font-size: 14px;
                border-radius: 2px;
                position: absolute;
                z-index: 5000;
                box-sizing: border-box;
                box-shadow: 0 1px 8px rgba(0,0,0,0.5);
                opacity: 0;
                visibility: hidden;
                transition: all 0.2s;
                i {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    margin-left: -12px;
                    width: 24px;
                    height: 12px;
                    overflow: hidden;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 50%;
                        transform: translate(-50%,50%) rotate(45deg);
                        background-color: #FFFFFF;
                        box-shadow: 0 1px 8px rgba(0,0,0,0.5);
                    }
                }
                ${responsive.PHABLET`
                    max-width: 320px;
                `}
            }
        }    
    }
`