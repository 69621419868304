import styled from "styled-components";
import FrameLarge from "Assets/News/FrameLarge.png";
import { COLORS, responsive } from "Styles/Constants";

export const RelatedNewsWrapper = styled.div.attrs({
  className: 'RelatedNewsWrapper',
})`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-right: 5px;
  padding: 10px;

  &:hover {
    .text-section {
      .related-news-title {
        color: ${COLORS.SECONDARY};
      }
      .related-news-text {
        text-decoration-color: #000;
      }
    }
  }

  ${responsive.TABLET`
    flex-direction: column;
    justify-content: flex-start;
`}

  ${responsive.PHABLET`
    height: max-content;
    width: 100%;
    max-width: 320px;
  `}

  .image-section {
    width: 50%;
    position: relative;

    ${responsive.TABLET`
        width: 100%;
    `}

    .border-container {
      position: relative;
      background-image: url(${FrameLarge});
      background-repeat: no-repeat;
      background-size: contain;
      width: 231px;
      height: 120px;
      margin: auto;

      ${responsive.DESKTOP`
        width: 206px;
      `}

      ${responsive.TABLET`
        height: 123px;
      `}
    }

    .contained-image {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      width: 204px;
      height: 112px;
      left: 4px;
      top: 4px;
  
      ${responsive.DESKTOP`
        width: 198px;
        height: 110px;
      `}
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    word-break: break-word;

    ${responsive.LAPTOP`
      padding: 0 0 0 20px;
    `}

    ${responsive.TABLET`
        width: 100%;
        padding: 12px 0;
    `}

    .related-news-title {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 10px;
      transition: all 0.2s;
    }
    .related-news-text {
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: all 0.2s;
    }
  }
`;
