import React from 'react';
import { SuccessWrapper } from './Success.style';
import PWILogo from "Assets/Group 1285.svg"
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const Success = (props) => {
    const { t } = useTranslation();
    const { REACT_APP_ACCOUNT_URL } = process.env;
    const language = useSelector(state => state.Auth.lang);

    const download = () => {
        window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=pwi`);
    }

    return (
        <>
        <div id="scroll-to-me" />
        <SuccessWrapper>
            <div className="logo-container">
                <img src={PWILogo} alt="LOGO" />
            </div>
            <div className="content-container">
                <Typography variant='h5' >
                    {/* THE GAME CLIENT IS BEING DOWNLOADED */}
                    {t("Success.ClientBeingDownloaded")}
                </Typography>
                <Typography variant="subtitle1" >
                    {t("Success.ClickHereMessage")} <a href={() => { }} onClick={download}><Typography component="span" color="secondary" className="hover-underline">{t("Success.ClickHereText")}</Typography></a>
                </Typography>
            </div>
        </SuccessWrapper>
        </>
    );
};

export default Success;