import styled from "styled-components";

// import HeaderLine from "Assets/images/Charge/Reward/header-line.svg";
// import HeaderLineReverse from "Assets/images/Charge/Reward/header-line-reverse.svg";
import ChargeContent from "Assets/Charge/charge_content.png";
import Character from "Assets/Charge/character.png";
import { COLORS, FONTS, responsive } from "Styles/Constants";

export const ChargeWrapper = styled.div.attrs({
  className: 'ChargeWrapper',
})`
  .header {
    display: flex;
    align-items: center;

    margin: 52px 0;

    ${responsive.TABLET`
            margin : 40px 0;
        `}

    ${responsive.PHABLET`
            margin : 20px 0;
        `}
        ${responsive.MOBILE`
            margin : 15px 0;
        `}
        /* .dummy-line {
            background-image: url(HeaderLine);
            height: 11px;
            width: 140px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            ${responsive.PHABLET`
                width : 100px;
                height : 9px;
            `}
            ${responsive.MOBILE`
                width : 80px;
                height : 7px;
            `}
            &.reverse {
                background-image: url(HeaderLineReverse);
            }
        } */

        .title {
      font-family: ${FONTS.PRIMARY_BOLD};
      text-transform: uppercase;
      height: 35px;
      display: flex;
      gap: 15px;
      width: 90%;
      margin: auto;

      h6 {
        font-weight: 600;
      }
      ${responsive.TABLET`
                font-size : 32px;
            `}
      ${responsive.PHABLET`
                font-size : 24px;
                height : 24px;
            `}
            ${responsive.MOBILE`
                font-size : 16px;
                height : 18px;
            `}
    }
  }

  .card-section {
    /* background : transparent linear-gradient(166deg, #453122 0%, #2C1606 100%) 0% 0% no-repeat padding-box; */
    background-image: url(${ChargeContent});
    height: max-content;
    width: 100%;
    position: relative;
    background-size: cover;

    .min-height {
      min-height: 435px ${responsive.PHABLET`
                   width: 100%;
                   min-height: max-content;
                `};
    }
    .top-section {
      display: flex;
      height: 350px;
      width: 90%;
      margin: auto;
      ${responsive.TABLET`
                height : 270px;
            `}
      ${responsive.PHABLET`
                height : 250px;
            `}
            ${responsive.MOBILE`
                height : 200px;
            `}
            .left-section {
        width: 40%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        max-width: 90%;
        /* margin: auto; */
        @media only screen and (max-width: 1080px) {
          width: 45%;
        }
        ${responsive.TABLET`
                    width : 100%;
                    max-width: 100%;
                    align-items: center;
                `}
        .expired-details-container {
          flex-direction: column;
          color: #fef2e6;
          gap: 1.5rem;
          @media only screen and (max-width: 855px) {
            .MuiTypography-h4 {
              font-size: 24px;
            }
            .MuiTypography-h6 {
              font-size: 18px;
            }
          }
        }
        .title {
          font-size: 16px;
          font-family: ${FONTS.PRIMARY_BOLD};
          color: #fef2e6;
          text-transform: uppercase;
          padding-left: 7px;
          ${responsive.PHABLET`
                        font-size : 14px;
                    `}
          ${responsive.MOBILE`
                        font-size : 12px;
                    `}
        }

        .time-section {
          display: flex;
          margin: 40px 0 0;

          .division {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 15px;

            .time {
              color: #fefefe;
              font-size: 60px;
              font-family: ${FONTS.SECONDARY};
              font-weight: 600;

              ${responsive.TABLET`
                                font-size : 54px;
                            `}

              ${responsive.PHABLET`
                                font-size : 48px;
                            `}
                            ${responsive.MOBILE`
                                font-size : 36px;
                            `}
            }
            .representation {
              color: #765d4c;
              font-weight: 600;
              font-size: 14px;
              text-transform: uppercase;

              ${responsive.MOBILE`
                                font-size : 12px;
                            `}
            }
          }
        }
      }

      .align-top {
        justify-content: start;
        margin-top: 2rem;
        ${responsive.TABLET`
                    width: 50%
                `}
        ${responsive.PHABLET`
                   width: 100%
                `}
      }
      .right-section {
        width: 60%;
        margin: 0 auto;
        position: relative;
        ${responsive.TABLET`
                    display : none;
                `}
        .character-image {
          position: absolute;
          /* width: 80%;
                    height: 90%;
                    left: 45%; */
          top: -43%;
          left: -10%;
          width: 100%;
          min-width: 737px;
          @media only screen and (max-width: 1650px) {
            left: -13%;
          }
          @media only screen and (max-width: 1550px) {
            min-width: 655px;
            top: -35%;
          }
          @media only screen and (max-width: 1275px) {
            /* min-width: 737px; */
            left: -14%;
          }
          @media only screen and (max-width: 1200px) {
            /* min-width: 707px; */
            left: -15%;
          }
          @media only screen and (max-width: 1150px) {
            min-width: 657px;
            top: -36%;
          }
          @media only screen and (max-width: 1080px) {
            min-width: 513px;
            top: -22%;
            left: -9%;
          }
        }
        .image {
          background-image: url(${Character});
          height: 400px;
          width: 475px;
          /* background-position: center; */
          background-repeat: no-repeat;
          background-size: contain;

          ${responsive.LAPTOP`
                        width : 400px;
                    `}
        }
      }

      .right-section-expired {
        width: 50%;
        margin: 0 auto;

        ${responsive.PHABLET`
                   display: none;
                `}
        .character-image {
          position: absolute;
          width: 50%;
          height: 90%;
          left: 45%;
          top: -19%;
        }
        .image {
          background-image: url(${Character});
          height: 400px;
          width: 475px;
          /* background-position: center; */
          background-repeat: no-repeat;
          background-size: contain;

          ${responsive.LAPTOP`
                        width : 400px;
                    `}
        }
      }
    }

    .bottom-section {
      padding: 0 0 20px;
      width: 90%;
      margin: 0 auto;

      ${responsive.TABLET`
                width : 90%;
            `}
      ${responsive.PHABLET`
                width : 70%;
            `}
            ${responsive.MOBILE`
                width : 90%;
            `}

            .cards {
        display: flex;
        justify-content: space-between;

        ${responsive.PHABLET`
                    flex-direction : column;
                    align-items : center;
                `}
        .reward-card {
          width: 32%;
          height: 230px;
          background-color: #f1ebe5;
          /* background-image: url(CardBG});
                    background-size: cover; */
          /* border : 3px solid #C0A371; */
          z-index: 10;
          position: relative;
          ${responsive.PHABLET`
                        width : 100%;
                        margin : 30px 0;
                    `}
          .right-frame {
            position: absolute;
            right: -4px;
            top: -9px;
            height: 247px;
          }
          .left-frame {
            position: absolute;
            left: -4px;
            top: -9px;
            height: 247px;
            transform: rotate(180deg);
          }

          .id {
            width: 45px;
            height: 45px;
            /* background-color: #D5A95B; */
            margin: 0 auto;
            display: flex;
            box-shadow: 0px 3px 12px #00000029;
            align-items: center;
            /* padding : 0 0 5px 0; */
            justify-content: center;
            font-family: ${FONTS.PRIMARY_BOLD};
            font-style: italic;
            font-size: 20px;
            transform: translate(0%, -70%);
          }

          .reward-title {
            font-size: 20px;
            /* font-style: italic; */
            font-family: ${FONTS.PRIMARY_BOLD};
            color: #000000;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 1rem;
            ${responsive.TABLET`
                            font-size : 16px;
                        `}
          }

          .reward-dummy-line {
            width: 80%;
            height: 0px;
            margin: 10px auto;
            background: transparent;
            border: 1px solid #c0a371;
          }
          .reward-description {
            font-size: 15px;
            color: #5c483d;
            text-align: center;
            width: 75%;
            margin: 0 auto;
            line-height: 24px;
            height: 72px;
            font-family: ${FONTS.SECONDARY};
            overflow: hidden;

            ${responsive.TABLET`
                            width : 90%;
                            font-size : 14px;
                        `}
          }

          .reward-visit {
            color: ${COLORS.SECONDARY_DARK};
            font-family: ${FONTS.PRIMARY_BOLD};
            margin: 20px 0;
            text-align: center;
            height: 20px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            &:hover {
              cursor: pointer;
              text-decoration-line: underline;
            }

            ${responsive.TABLET`
                            font-size: 14px;
                        `}
          }
        }
      }

      .conditional-text {
        display: flex;
        margin: 24px 0 0;
        justify-content: space-between;
        ${responsive.PHABLET`
                    flex-direction : column;
                `}
        .description-container {
          color: #aaa;
          font-size: 16px;
          width: 32%;
          line-height: 24px;
          font-family: ${FONTS.SECONDARY};
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          /* padding: 0 0.5rem; */
          ${responsive.PHABLET`
                        margin-top: 1rem;
                    `}
          strong {
            color: #fef2e6;
            font-weight: 600;
          }

          .MuiTypography-subtitle1 {
            color: #b29271;
          }
          ${responsive.TABLET`
                        font-size : 14px;
                    `}
          ${responsive.PHABLET`
                        font-size : 12px;
                        width : 100%;
                    `}

                    &.last {
            margin: 0 0 0 auto;

            ${responsive.PHABLET`
                            margin : 20px 0 0 auto;
                        `}
          }
        }
      }
    }
  }
`;
