import React from 'react';

const UpArrowIcon = (props) => {
    const { className } = props
    return(
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="39.214" height="20.147" viewBox="0 0 39.214 20.147">
            <defs><style>{'.a{fill:#ac1818;}'}</style></defs>
            <path className="a" d="M357.308,420.892,338.218,401.8,319.152,420.87l-.529-.529,19.6-19.6,19.618,19.618Z" transform="translate(-318.623 -400.745)"/>
            <path className="a" d="M327.989,424.034l13.681-13.682L355.516,424.2Z" transform="translate(-322.146 -404.358)"/>
        </svg>
    )
}

export default UpArrowIcon;