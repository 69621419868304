import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

// ROUTING COMPONENTS
// import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import { URL_NEWS_DETAILS, URL_CHARGE_HISTORY, URL_CHARGE, URL_SUCCESS, URL_NEWS, URL_SHOP } from "Helpers/Paths";
import Home from 'Components/Home/Home';
import NewsDetails from 'Components/Pages/News/NewsDetails.jsx/NewsDetails';
import Charge from 'Components/Pages/Charge/Charge';
import Success from 'Components/Pages/Success/Success';
import News from "Components/Pages/News/NewsPage"
import PageNotFound from 'Components/PageNotFound';

const LANG_REGEX = "en|de|fr";

function Routes({ lang }) {
    const { REACT_APP_DOWNLOAD_URL } = process.env;

    return (
        <Switch>
            {/* <Route path={URL_LOGIN} component={Login} /> */}
            <Route       exact path={`/:lang(${LANG_REGEX})`} component={Home} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_SHOP} render={() => window.location.replace(`${REACT_APP_DOWNLOAD_URL}/${lang}/store/pwi/list`)} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_NEWS_DETAILS + "/:newsId"} component={NewsDetails} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_NEWS} component={News} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_CHARGE_HISTORY} component={Charge} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_CHARGE} component={Charge} />
            <Route       exact path={`/:lang(${LANG_REGEX})` + URL_SUCCESS} component={Success} />
            <Route       exact path="/">
                <Redirect to={`/${lang}/`} />
            </Route>
            <Route path={"*"} component={PageNotFound} />
            {
                RoutesList.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                ))
            }
        </Switch>
    );
}

export default Routes;
