import LazyLoader from "@loadable/component";

import { URL_USER, URL_NEWS, URL_CHARGE, URL_HOME } from "Helpers/Paths";

export default [
  {
    path: "/",
    exact: true,
    component: LazyLoader(() => import("Components/Home/Home")),
  },
  {
    path: URL_HOME,
    exact: true,
    component: LazyLoader(() => import("Components/Home/Home")),
  },
  {
    path: URL_USER,
    exact: true,
    component: LazyLoader(() => import("Components/User/User")),
  },
  {
      path: URL_NEWS,
      exact: true,
      component: LazyLoader(() => import("Components/Pages/News/NewsPage"))
  },
  {
    path: URL_CHARGE,
    exact: true,
    component: LazyLoader(() => import("Components/Pages/Charge/Charge"))
  },

];
