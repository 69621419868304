import React, { useState } from 'react';
import { RedeemDialog } from './RedeemDialog.style';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Typography, CircularProgress } from '@material-ui/core';
import { URL_CHARGE_HISTORY } from 'Helpers/Paths';
import { useHistory } from 'react-router-dom';
import HeaderIcon from 'Assets/Icons/HeaderIcon';
import PWIButton from "Components/Commons/Button/Button";
import { useSelector } from 'react-redux';
import Api from 'Helpers/ApiHandler';
import { useTranslation, Trans } from 'react-i18next';

function RedeemRewards(props) {
    const { campaignId, data, serverCharacterArray, open, onClose, getCampaignData } = props;
    const { REACT_APP_ACCOUNT_URL } = process.env;
    const history = useHistory();
    const language = useSelector(state => state.Auth.lang);
    const { t } = useTranslation();

    const [confirmed, setConfirmedStatus] = useState(false);
    const [redeeming, setRedeeming] = useState(false);
    const [selectedServer, setSelectedServer] = useState(null);
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    const claimReward = async () => {
        if (!selectedServer?.id) {
            setErrorMsg(t("charge.dialog.error.server"));
            return;
        }

        if (!selectedCharacter?.id) {
            setErrorMsg(t("charge.dialog.error.character"));
            return;
        }

        setRedeeming(true);

        try {
            await new Api().post('chargerewards/pwi/claims', {
                data: {
                    server_id: selectedServer.id,
                    character_id: selectedCharacter.id,
                    campaign_id: campaignId,
                    item_id: data.id,
                    reward_id: data.reward_id,
                }
            })

            setErrorMsg("");
        } catch (error) {
            setErrorMsg(
                <Trans i18nKey="charge.dialog.error.failed">
                    "Redemption failed. There was an error when attempting to grant the item. Please try again later or contact <a href={`${REACT_APP_ACCOUNT_URL}${language}/supportredirect/app/home/`} target="_blank" rel="noopener noreferrer">Customer Support</a> for assistance."
                </Trans>
            );
        }

        getCampaignData();
        setConfirmedStatus(true);
        setRedeeming(false);
    }

    const onChangeServer = (serverName) => {
        const server = serverCharacterArray?.servers?.find(server => server.name === serverName);
        setSelectedServer(server);
        setSelectedCharacter(null);
        if (errorMsg) {
            setErrorMsg("");
        }
    }
    const onChangeCharacter = (characterName) => {
        const character = serverCharacterArray?.characters?.find(role => (role.name === characterName && role.server.id === selectedServer.id));
        setSelectedCharacter(character);
        if (errorMsg) {
            setErrorMsg("");
        }
    }

    return (
        <RedeemDialog
            open={open}
            onClose={onClose}
            classes={{
                paper: "paper"
            }}
        >
            <div className="container">
                <div className="close" onClick={() => onClose()}></div>
                <div className="content">
                    <div className="title flex">
                        <HeaderIcon />
                        <Typography variant="h6" >
                            {t("charge.dialog.redemption")}
                        </Typography>
                    </div>

                    {(serverCharacterArray.servers.length === 0 || serverCharacterArray.characters.length === 0) && 
                        <div className="error">{t("charge.dialog.no.selection")}</div>
                    }
                    {!confirmed && !redeeming && serverCharacterArray.servers.length > 0 &&
                        <React.Fragment>
                            <div className="chosen">{t("charge.dialog.choose.redeem")}<span className="item">{" "}<b>{data.title || data.name}</b></span></div>

                            <div className="spend-points">{t("charge.dialog.description.part.one")}
                                <span className="highlight"><b>{t("charge.dialog.description.part.two")}</b></span>
                                {t("charge.dialog.description.part.three")}
                                <span className="highlight"><b>{`${data.cost} RP`}</b></span>
                                {t("charge.dialog.description.part.five")}
                            </div>

                            <div className="server">
                                <Autocomplete
                                    disablePortal
                                    classes={{
                                        root: "auto-root",
                                        input: "auto-input",
                                    }}
                                    options={serverCharacterArray.servers.map((option) => option.name)}
                                    onChange={(e, server) => onChangeServer(server)}
                                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("charge.dialog.dropdown.server")} />}
                                />
                            </div>

                            {selectedServer && <div className="character">
                                <Autocomplete
                                    disablePortal
                                    classes={{
                                        root: "auto-root",
                                        input: "auto-input",
                                    }}
                                    inputValue={selectedCharacter?.name || ""}
                                    value={selectedCharacter}
                                    options={serverCharacterArray.characters.filter((role) => role.server.id === selectedServer.id).map((role) => role.name)}
                                    onChange={(e, character) => onChangeCharacter(character)}
                                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t("charge.dialog.dropdown.character")} />}
                                />
                            </div>}
                            {errorMsg && <div className="error">{errorMsg}</div>}
                        </React.Fragment>
                    }
                    {redeeming &&
                        <div className="loading-container">
                            <div className="loading-text">{t("charge.dialog.loading")}</div>
                            <CircularProgress color="secondary" size={40} />
                        </div>
                    }
                    {confirmed && errorMsg && <div className="error confirmed-error">{errorMsg}</div>}
                    {confirmed && !errorMsg &&
                        <React.Fragment>
                            <div className="success">{t("Charge.Success")}</div>
                            <div className="desc">{t("Charge.ReviewMessage")}</div>
                        </React.Fragment>
                    }
                    {confirmed && <div className="history" onClick={() => history.push("/" + language + URL_CHARGE_HISTORY)}>{t("charge.dialog.view.redemption.history")}</div>}
                </div>
                <div className="buttons">
                    {!confirmed && !redeeming && serverCharacterArray.servers.length > 0 && 
                        <React.Fragment>
                            <PWIButton className="btn primary" disabled={redeeming} onClick={claimReward}>{t("charge.dialog.description.part.two")}</PWIButton>
                            <PWIButton className="btn secondary" onClick={() => onClose()}>{t("charge.dialog.cancel")}</PWIButton>
                        </React.Fragment>
                    }
                    {(redeeming || confirmed || serverCharacterArray.servers.length === 0) && <PWIButton className="btn secondary" onClick={() => onClose()}>{t("charge.dialog.close")}</PWIButton>}
                </div>
            </div>
        </RedeemDialog>
    )
}

export default RedeemRewards