import React from 'react';
import { CarousalContentWrapper } from './Carousal.style';
import Button from 'Components/Commons/Button/Button';
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"
import FrameLarge from "Assets/News/FrameLarge.png";

const Carousal = (props) => {
    const { t } = useTranslation();
    const { item } = props;

    let darkUserAgent = navigator?.userAgent.includes('PWECoreClient') || navigator?.userAgent.includes('PWECoreOverlay');
    let history = useHistory();
    const language = useSelector(state => state.Auth.lang);

    return (
        <CarousalContentWrapper darkTheme={darkUserAgent}>
            <div className="image-container" >
                <div className='container' style={{ backgroundImage: `url(${item?.media ? item?.media[0]?.image?.url : ""})` }}>
                    <div className='border-container' style={{ backgroundImage: `url(${FrameLarge})` }}></div>
                </div>
            </div>
            <div className="content-container f-h-center flex" >
                <Typography className="header" variant="h5">
                    {/* JOIN THE FIGHT TO PROTECT PERFECT WORLD! */}
                    {item?.title}
                </Typography>
                <Typography className="sub-txt" variant="subtitle1">
                    {/* Travel to the Western Continent and disrupt nefarious plans to spread corruption. Ensure the sun always rises! */}
                    {item?.summary}
                </Typography>
                <Button onClick={() => history.push({ pathname: "/" + language + "/news-details/" + item.id })} style={{ marginTop: "1.5rem" }}>
                    <Typography variant="button">
                        {t("News.READMORE")}
                    </Typography>
                </Button>
            </div>
        </CarousalContentWrapper>
    );
}

export default Carousal;
