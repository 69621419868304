import styled from "styled-components";
import BGIMAGE from "Assets/3rd_banner.png"
import { responsive } from "Styles/Constants";

export const SuccessWrapper = styled.div.attrs({
    className: 'SuccessWrapper',
  })`
    width: 100%;
    min-height: 800px;
    background-image: url("${BGIMAGE}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${responsive.PHABLET`
        min-height: 609px;
    `}
    .logo-container {
        justify-content: center;
        img {
            width: 117px;
            height: 122px;
        }
    }
    .content-container {
        width: 100%;
        text-align: center;
    }
    h4, h5 {
        color: #fef2e6;
        font-weight: 800;
    }
    h5 {
        
        margin-top: 2rem;
    }

    h6 {
        color: #d2c2b2;
        a {
            cursor: pointer;
        }
    }
`;