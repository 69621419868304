import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import ArrowIcon from "Assets/Charge/arrow-icon.png";
import DoubleArrowDefaultIcon from "Assets/History/double_arrow_default.png"
import DoubleArrowIcon from "Assets/History/double_arrow.png"
import NextArrowIcon from "Assets/History/single_arrow.png"
import NextArrowDefaultIcon from "Assets/History/single_arrow_default.png"

export const HistoryWrapper = styled.div.attrs({
    className: 'HistoryWrapper',
  })`
    margin : 40px 0 0;

    .history-header {
        display: flex;
        justify-content: space-between;
    }

    .view-rewards {
        text-decoration: underline;
        font-size: 16px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        margin : 0 0 10px 0;
        color: #8b1009;
        font-weight: 600;
        &:hover {
            cursor: pointer;
        }
    }

    .redemption-history-title {
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_BOLD};
        text-transform: uppercase;
        text-decoration: none;
        color: #321a19;
        font-weight: 600;
    }

    .list {
        margin : 10px 0;

        .list-item {
            display: flex;
            height: 40px;
            margin: 1px 0;
            font-size: 16px;
            
            ${responsive.PHABLET`
                font-size : 14px;
            `}
            ${responsive.MOBILE`
                font-size : 12px;
            `}
            .key {
                background-color: #572F00;
                color : #fff;
                width : 30%;
                padding: 0 0 0 20px;
                display: flex;
                align-items: center;          
                
                ${responsive.PHABLET`
                    padding: 0 0 0 10px;
                    width : 40%;
                `}
            }
            .value {
                background-color: #DFDBD5;
                color : #614F4F;
                width : 70%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 20px 0 0;

                ${responsive.PHABLET`
                    padding: 0 10px 0 0;
                `}
                &.reward {
                    font-family: ${FONTS.PRIMARY_BOLD};
                }
                &.points {
                    color : green;
                }
                &.minus {
                    color : red;
                }
            }
        }
    }
    .table-header {
        width: 100%;
        background-color: #572F00;
        display: flex;
        height: 48px;
        margin : 20px 0 0;
        
        .item {
            color : #fff;
            /* width: calc(100% / 8); */
            display: flex;
            align-items: center;
            padding-left: 12px;
            @media only screen and (max-width: 740px) {
                font-size: 14px;
            }
            @media only screen and (max-width: 636px) {
                font-size: 12px;
            }
        }

    }
    .table-body {
        width: 100%;
        height: max-content;
        &.no-content {
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .body-item {
            display: flex;
            :nth-child(odd){
                background-color: #efe8dc;
            }
            :nth-child(even){
                background-color: #ECECEC;
            }
        }
        .item {
            color : #614F4F;
            height: 40px;
            align-items: center;
            display: flex;
            padding-left: 12px;
            font-family: ${FONTS.SECONDARY};
            &.action {
                width : 12%;
            }
            &.reward {
                width : 14%;
                font-family: ${FONTS.PRIMARY_BOLD};
                color: #503b2f;
                font-weight: 600;
            }
            &.quantity {
                width : 10%;
            }
            &.points {
                width : 12%;
                color : green;

                &.minus {
                    color : red;
                }
            }
            &.rp_balance {
                width : 12%;
            }
            &.character {
                width : 12%;
            }
            &.server {
                width : 13%;
            }
            &.time {
                width : 15%;
                padding : 0 3px;
            }
        }
    }
    .pagination-container {
        width: 100%;
        display: flex;
        margin-top: 1rem;
        & > nav {
            margin: auto;
            ul > li {
                ${responsive.PHABLET`
                    width: 12%;
                    display: flex;
                    justify-content: center;
                `}
            }
        }
        .pagination-btn {
            cursor: not-allowed;
            pointer-events: all;
        }

        .pagination-btn:not(:disabled) {
            cursor: pointer;
        }

        .MuiPaginationItem-root {
            font-family: "Source Sans Pro";
            font-weight: 600;
            color: #c1b2a4;
            font-size: 1.2rem;
        }
        .MuiPaginationItem-page.Mui-selected {
            color: black;
            background-color: transparent;
        }

        .double-arrow {
            background-image: url(${DoubleArrowDefaultIcon});
            width: 16px;
            height: 22px;
            background-repeat: no-repeat;
            background-position: center;
            &:hover {
                background-image: url(${DoubleArrowIcon});
            }
        }

        .single-arrow {
            background-image: url(${NextArrowDefaultIcon});
            width: 12px;
            height: 22px;
            background-repeat: no-repeat;
            background-position: center;
            &:hover {
                background-image: url(${NextArrowIcon});
            }
        }

        .flip {
            transform: rotate(180deg);
        }

    }
    .pagination {
        display: flex;
        margin : 20px 0;
        justify-content: flex-end;

        .page {
            font-size: 14px;
            color: #6F7070;
            margin : 0 10px 0 0;
        }
        .prev {
            background-image: url(${ArrowIcon});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            margin : 0 5px;
            transform: rotate(90deg);

            &:hover {
                cursor: pointer;
            }
        }
        .next {
            background-image: url(${ArrowIcon});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            margin : 0 5px;
            transform: rotate(-90deg);
            
            &:hover {
                cursor: pointer;
            }
        }
    }
`