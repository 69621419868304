import styled from "styled-components";

export const BackToTop = styled.div`
  height: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  .up-arrow {
    @media (hover: hover) {
      transition: transform 300ms ease-in-out;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }

  }
`;
